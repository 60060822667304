import React, { useState, useContext, useEffect } from 'react';
import { HStack } from 'native-base';
import {
  Text,
  View,
  ScrollView,
  Pressable,
  KeyboardAvoidingView,
  ActivityIndicator,
  Image,
  Platform,
  Modal,
  StyleSheet,
  TextInput
} from 'react-native';
import { useIsFocused } from '@react-navigation/native';
import { DataTable } from 'react-native-paper';
import {
  widthPercentageToDP as wp,
  heightPercentageToDP as hp,
} from 'react-native-responsive-screen';
import { TextInputMask } from 'react-native-masked-text';
import { Picker } from '@react-native-picker/picker';
import { AuthContext } from '../../components/AuthProvider';
import AxiosInstance from '../../components/common/axiosInstance';

import Header from '../../components/common/Header';
import Footer from '../../components/common/Footer';
import Loader from '../../components/common/Loader';
import ErrorModal from '../../components/common/ErrorModal';
import InfoModal from '../../components/common/InfoModal';
import { ReferalHeaderCard, ReferalHeaderTable, ReferalCard, ReferalTable } from './ReferalItem';
import { capitalizeFirstLetter, formatCardNumber } from '../../components/common/commonFunctions';

import ArrowLeft from '../../assets/img/arrow_left_white.svg';

import { default as commonStylesF } from '../../styles/common';
import { default as commonStylesMobileF } from '../../styles/common_mobile';
import { default as listStylesF } from '../../styles/list';
import { default as balanceStylesF } from '../../styles/balance';
import { default as balanceStylesMobileF } from '../../styles/balance_mobile';
import { default as profileStylesF } from '../../styles/profile';
import { default as profileStylesMobileF } from '../../styles/profile_mobile';
import IOSCustomPagination from '../../components/common/IOSCustomPagination';
import Config from '../../config/config';

const Moneybox = ({ navigation, route }) => {
  const {
    user,
    logout,
    t,
    screenWidth,
    theme,
    addHistory,
    currency,
    userCredentials,
  } = useContext(AuthContext);

  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(null);
  const [info, setInfo] = useState(null);
  const [referals, setReferals] = useState([]);
  const [ordersStat, setOrdersStat] = useState({});
  const [refOrdersStat, setRefOrdersStat] = useState({});
  const [stat, setStat] = useState({
    cashback_calculated: { 'total': 0, 'completed': 0, 'active': 0 },
    available: 0,
    available_withdraw: 0,
    waiting_withdraws: 0,
    completed_withdraws: 0,
    deficit: 0,
    proficit: 0,
    moneybox_total: 0,
    moneybox_available_withdraw: 0,
    moneybox_waiting_withdraws: 0,
    moneybox_completed_withdraws: 0,
  });
  const [currentPage, setCurrentPage] = useState(1);
  const [pagination, setPagination] = useState({});
  const [sortField, setSortField] = useState('created_at');
  const [sortDirection, setSortDirection] = useState('desc');
  const isFocused = useIsFocused();
  const [modalVisible, setModalVisible] = useState(false);
  const [withdraw, setWithdraw] = useState(0);
  const [isShowKeyboard, setIsShowKeyboard] = useState(false);
  const [prevScreenWidth, setPrevScreenWidth] = useState(screenWidth);
  const [from, setFrom] = useState(0);
  const [to, setTo] = useState(0);
  const [userCards, setUserCards] = useState([]);
  const [selectedCard, setSelectedCard] = useState(userCredentials?.card_number ?? '');
  const [cardError, setCardError] = useState(false);
  const [needReload, setNeedReload] = useState(false);

  let fontSizeBig =
    Math.ceil(screenWidth / 18) < 40 ? Math.ceil(screenWidth / 12) : 40;
  let fontSizeMd =
    Math.ceil(screenWidth / 20) < 24 ? Math.ceil(screenWidth / 14) : 24;
  const commonStyles =
    screenWidth < 1080
      ? commonStylesMobileF(wp, hp, theme)
      : commonStylesF(wp, hp, theme);
  const balanceStyles =
    screenWidth < 1080
      ? balanceStylesMobileF(wp, hp, theme)
      : balanceStylesF(wp, hp, theme);
  let profileStyles =
    screenWidth < 1080
      ? profileStylesMobileF(wp, hp, fontSizeBig, fontSizeMd, theme)
      : profileStylesF(wp, hp, fontSizeBig, fontSizeMd, theme);
  const listStyles = listStylesF(theme);

  let ReferalHeader = screenWidth < 700 ? ReferalHeaderCard : ReferalHeaderTable;
  let Referal = screenWidth < 700 ? ReferalCard : ReferalTable;

  const doWithdraw = async () => {
    if (!selectedCard) {
      setCardError(true);
      return false;
    }
    setIsLoading(true);
    const api = AxiosInstance({ user, logout });
    await api('/api/v1/withdraw', {
      method: 'POST',
      mode: 'cors',
      data: {
        amount: parseFloat(withdraw),
        program: 'moneybox',
        card_id: selectedCard
      },
    })
      .then(response => {
        if (response.status === 201) {
          // setWithdraw(0);
          setInfo(t('withdraw_request_sended'));
          setNeedReload(!needReload);
        } else if (response.status === 'error') {
          setIsError(t('somthing_wrong'));
          console.log('withdraw request bad request status', response);
        }
      })
      .catch(error => {
        setIsError(error);
        console.log('withdraw request error', error);
      })
      .finally(() => {
        setIsLoading(false);
        setModalVisible(false);
      });
  };

  useEffect(() => {
    const getStats = async () => {
      setIsLoading(true);
      const api = AxiosInstance({ user, logout });
      await api(`${Config.API_URL}/api/v1/cashback?page=${currentPage}&order_field=${sortField}&order=${sortDirection}`, {
        method: 'GET',
        mode: 'cors',
      }).then(response => {
        let _from = 0;
        let _to = 0;
        if (response.status === 200) {
          setReferals(response.data.message.referals.data);
          setOrdersStat(response.data.message.user_orders);
          setRefOrdersStat(response.data.message.ref_orders);
          setStat({
            cashback_calculated: response.data.message.cashback,
            available: response.data.message.available,
            available_withdraw: response.data.message.available_withdraw,
            waiting_withdraws: response.data.message.waiting_withdraws,
            completed_withdraws: response.data.message.completed_withdraws,
            deficit: response.data.message.deficit,
            proficit: response.data.message.proficit,
            moneybox_total: response.data.message.moneybox_total,
            moneybox_available_withdraw: response.data.message.moneybox_available_withdraw,
            moneybox_waiting_withdraws: response.data.message.moneybox_waiting_withdraws,
            moneybox_completed_withdraws: response.data.message.moneybox_completed_withdraws,
          });
          setPagination(response.data.message.referals.pagination);
          _from =
            response.data.message.referals.pagination.totalRows > 0
              ? (currentPage - 1) *
              response.data.message.referals.pagination.perPage +
              1
              : 0;
          _to =
            response.data.message.referals.pagination.totalRows >
              currentPage * response.data.message.referals.pagination.perPage
              ? currentPage *
              response.data.message.referals.pagination.perPage
              : response.data.message.referals.pagination.totalRows;
          setFrom(_from);
          setTo(_to);
          // console.log('REFERALS', response.data.message.available);
        }
      }).catch(error => {
        console.log(error);
      }).finally(() => {
        setIsLoading(false);
      });
    };
    if (isFocused) {
      getStats();
    }
  }, [user, logout, currentPage, sortField, sortDirection, isFocused, needReload]);

  useEffect(() => {
    const getUserCards = async () => {
      const api = AxiosInstance({ user, logout });
      await api(`/api/v1/user_cards`, {
        method: 'GET',
        mode: 'cors',
      })
        .then(response => {
          if (response.data && response.data.status === 'success') {
            console.log('user cards', response.data.message);
            setUserCards(response.data.message);
          } else {
            setIsError(t('something_wrong'));
          }
        })
        .catch(error => {
          setIsError(t(`${error}`));
        })
    }

    getUserCards();
  }, [logout, t, user]);

  useEffect(() => {
    if (Platform.OS !== 'web') {
      addHistory(route.name);
    }
  }, [addHistory, route]);
  if (isLoading) {
    return <Loader />
  } else {
    return (
      <ScrollView contentContainerStyle={commonStyles.container}>
        <Header navigation={navigation} route={route} />
        <View style={commonStyles.contentContainer}>
          <View style={commonStyles.headerContainer}>
            <Pressable
              style={commonStyles.headBackButton}
              onPress={() => navigation.navigate('Profile')}>
              {Platform.OS === 'web' ? (
                <Image source={ArrowLeft} style={commonStyles.arrowLeft} />
              ) : (
                <ArrowLeft style={commonStyles.arrowLeft} />
              )}
            </Pressable>
            <View style={commonStyles.contentHead}>
              <Text style={commonStyles.contentHeadText}>{t('moneybox')}</Text>
            </View>
          </View>
          <View style={commonStyles.headerDescription}>
            <Text style={commonStyles.headerDescriptionText}>{t('moneybox_description')}</Text>
          </View>
          <View style={balanceStyles.cashbackStats}>
            <View style={balanceStyles.cashbackStatBlack}>
              <Text style={balanceStyles.cashbackStatTxt1}>{t('referals_orders_sum')}</Text>
              <Text style={{ ...balanceStyles.cashbackStatTxt2, height: 20 }}>
                {refOrdersStat?.cash?.total_sum || 0} {t(currency)}
              </Text>
              {/* <Text style={balanceStyles.cashbackStatTxt3}>{t(currency)}</Text> */}
              <Text style={{ ...balanceStyles.cashbackStatTxt3, height: 20 }}>{t('infobox_accumulated')}</Text>
              <Text style={{ ...balanceStyles.cashbackStatTxt2, height: 20 }}>
                {stat?.moneybox_total || 0} {t(currency)}
              </Text>
              {/* <Text style={balanceStyles.cashbackStatTxt3}>{t(currency)}</Text> */}
            </View>
            {/* <View style={balanceStyles.cashbackStatBlue}>
              <Text style={balanceStyles.cashbackStatTxt1}>{t('available_shop_cashback')}</Text>
              <Text style={balanceStyles.cashbackStatTxt2}>
                {stat?.moneybox_available_withdraw || 0} {t(currency)}
              </Text>
              <Text style={balanceStyles.cashbackStatTxt3}>{t('available_withdraw_cashback')}</Text>
              <Text style={balanceStyles.cashbackStatTxt2}>
                {stat?.moneybox_available_withdraw || 0} {t(currency)}
              </Text>
            </View> */}
            <View style={balanceStyles.cashbackStatBlue}>
              <Text style={balanceStyles.cashbackStatTxt1}>{t('admin_withdraw_requests')} </Text>
              <Text style={{ ...balanceStyles.cashbackStatTxt2, height: 20 }}>
                {stat?.moneybox_waiting_withdraws || 0} {t(currency)}
              </Text>
              <Text style={{ ...balanceStyles.cashbackStatTxt3, height: 20 }}>{t('withdraw_completed')}</Text>
              <Text style={{ ...balanceStyles.cashbackStatTxt2, height: 20 }}>
                {stat?.moneybox_completed_withdraws || 0} {t(currency)}
              </Text>
            </View>
          </View>
          <HStack style={balanceStyles.balanceFooterContainer}>
            <HStack style={{ ...balanceStyles.btnGroup, justifyContent: "center" }}>
              <Pressable
                style={balanceStyles.balanceBtnWithdraw}
                onPress={() => {
                  setWithdraw(stat?.moneybox_available_withdraw || 0);
                  setModalVisible(true);
                }}>
                <Text style={balanceStyles.btnTextWhite}>
                  {t('do_withdraw_request')}
                </Text>
              </Pressable>
            </HStack>
          </HStack>
          <View style={commonStyles.tableHead}>
            <Text style={commonStyles.tableHeadText}>{t('referal_orders')}</Text>
          </View>
          {Platform.OS === 'ios' ? (
            // Кастомна пагінація і рендеринг даних для iOS
            <View style={listStyles.dataTable}>
              <ReferalHeader
                sortField={sortField}
                sortDirection={sortDirection}
                setSortField={setSortField}
                setSortDirection={setSortDirection}
              />
              {referals?.data?.map(item => (
                <Referal item={item} key={item.id} />
              ))}
              <IOSCustomPagination
                currentPage={currentPage}
                totalPages={Math.ceil(pagination.totalPages)}
                onPageChange={newPage => setCurrentPage(newPage)}
                label={`${from}-${to} of ${pagination.totalRows}`}
              />
            </View>
          ) : (
            // Стандартна пагінація для інших платформ
            <DataTable style={listStyles.dataTable}>
              <ReferalHeader
                sortDirection={sortDirection}
                setSortDirection={setSortDirection}
                sortField={sortField}
                setSortField={setSortField}
              />

              {referals && referals.map(item => (
                <Referal item={item} navigation={navigation} key={item.id} />
              ))}

              <DataTable.Pagination
                page={currentPage - 1}
                numberOfPages={Math.ceil(pagination.totalPages)}
                onPageChange={page => setCurrentPage(page + 1)}
                label={`${from}-${to} of ${pagination.totalRows}`}
                numberOfItemsPerPage={pagination.perPage}
                showFastPaginationControls
              />
            </DataTable>
          )}
        </View>
        <Footer navigation={navigation} />
        {isError && <ErrorModal error={isError} setIsError={setIsError} />}
        {info && <InfoModal info={info} setInfo={setInfo} />}
        {isLoading === true && (
          <ActivityIndicator
            style={styles.activityIndicator}
            color={theme.BLUE}
            size="large"
          />
        )}
        {modalVisible && (
          <KeyboardAvoidingView
            behavior={Platform.OS === 'ios' ? 'padding' : ''}>
            <Modal
              animationType="none"
              transparent={true}
              visible={true}
              onRequestClose={() => {
                setModalVisible(false);
              }}
              style={{ alignItems: 'center', justifyContent: 'center' }}>
              <View
                style={{
                  ...balanceStyles.modalContainer,
                  marginBottom: isShowKeyboard ? 20 : 150,
                }}>
                <View style={{ ...balanceStyles.modalContent, height: 'auto' }}>
                  <View style={balanceStyles.modalHead}>
                    <Pressable onPress={() => setModalVisible(false)}>
                      <Image
                        style={balanceStyles.iconClose}
                        source={require('../../assets/img/icon_close.png')}
                      />
                    </Pressable>
                  </View>

                  <View style={{ ...balanceStyles.modalBody, height: 'auto', marginBottom: 20 }}>
                    <Text style={{ ...balanceStyles.modalCaption, fontSize: 18 }}>
                      {capitalizeFirstLetter(t('form_withdraw_request'))}
                    </Text>
                    <View style={balanceStyles.inputContainer}>
                      <Text style={balanceStyles.sumLabel}>{t('sum')}</Text>
                      <TextInput
                        style={balanceStyles.sumInput}
                        onChangeText={text => setWithdraw(text)}
                        // readOnly={true}
                        value={withdraw}
                        onFocus={() => setIsShowKeyboard(true)}
                        isFocused={true}
                      />
                    </View>
                    <View style={balanceStyles.inputContainer}>
                      {cardError
                        ? <Text style={{ ...balanceStyles.sumLabel, color: 'red' }}>{t('error_select_card')}</Text>
                        : <Text style={balanceStyles.sumLabel}>{t('card')}</Text>
                      }
                      <Picker
                        style={profileStyles.formInput}
                        selectedValue={selectedCard ?? ''}
                        onValueChange={(itemValue) => {
                          setSelectedCard(itemValue);
                          if (itemValue && cardError) {
                            setCardError(false);
                          }
                        }}
                      >
                        <Picker.Item key={0} label={t('select')} value="" />
                        {userCards.length > 0 && userCards.map((card) => (
                          <Picker.Item key={card.id} label={formatCardNumber(card.card_number)} value={card.id} />
                        ))}
                      </Picker>
                    </View>
                    <HStack style={balanceStyles.modalBtnGroup}>
                      <Pressable
                        style={balanceStyles.modalBtn}
                        onPress={() => {
                          setModalVisible(false);
                          setIsShowKeyboard(false);
                        }}>
                        <Text style={balanceStyles.btnTextWhite}>
                          {t('cancel')}
                        </Text>
                      </Pressable>
                      <Pressable
                        style={balanceStyles.modalBtn}
                        onPress={() => {
                          doWithdraw();
                          setIsShowKeyboard(false);
                        }}>
                        <Text style={balanceStyles.btnTextWhite}>
                          {t('do_withdraw_request')}
                        </Text>
                      </Pressable>
                    </HStack>
                    {/* <Text style={styles.error}>Errors: {errors ? JSON.stringify(errors) : ''}</Text> */}
                  </View>
                </View>
              </View>
            </Modal>
          </KeyboardAvoidingView>
        )}
      </ScrollView>
    );
  }
}

const styles = StyleSheet.create({
  activityIndicator: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'transparent', // Прозрачный фон
  }
});

export default Moneybox;