import React, {useContext} from 'react';
import { View, Text, StyleSheet } from 'react-native';
import {AuthContext} from '../../components/AuthProvider';

const getColorForPercentage = (percentage) => {
  const red = percentage < 50 
    ? 255 
    : Math.floor(255 - ((percentage - 50) / 50) * 255); // Уменьшается после 50%
  const green = percentage < 50 
    ? Math.floor((percentage / 50) * 255) // Увеличивается до 50%
    : 255;
  return `rgb(${red},${green},0)`; // Цвет в формате RGB
};

const BatteryIndicator = ({ percentage, status }) => {
  const { t } = useContext(AuthContext);
  const fillColor =
    status === "closed"
    ? "#bbb"
    : getColorForPercentage(percentage);
  const status_text =
    status === "closed"
    ? t("status_closed")
    : status === "completed"
    ? t("status_completed")
    : status === "active"
    ? t("status_active")
    : status === "withdraw_requested"
    ? t("status_requested")
    : status === "withdraw_paid"
    ? t("status_paid")
    : "";

  return (
    <View style={styles.container}>
      <View style={styles.batteryBorder}>
        {/* Заполненная часть батареи */}
        <View style={[styles.batteryFill, { width: `${percentage}%`, backgroundColor: fillColor }]} />
        <Text style={styles.statusText}>{t(status_text)}</Text>
      </View>
      <View style={styles.textWrapper}>
        <Text style={styles.percentageText}>{percentage}%</Text>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    alignItems: 'center',
    width: 140,
  },
  batteryBorder: {
    width: 80,
    height: 40,
    borderWidth: 2,
    borderColor: '#000',
    backgroundColor: '#fff',
    borderRadius: 5,
    position: 'relative',
    justifyContent: 'center', // Центрирование по вертикали
    alignItems: 'center', // Центрирование по горизонтали
  },
  batteryFill: {
    height: '100%',
    borderRadius: 3,
    position: 'absolute', // Размещаем fill внутри контейнера
    left: 0,
    top: 0,
  },
  textWrapper: {
    flex: 1, // Это позволит занять всё доступное пространство
  },
  percentageText: {
    marginLeft: 10,
    fontSize: 18,
    color: '#fff',
    textAlign: 'right',
  },
  statusText: {
    fontSize: 14,
    fontFamily: 'Mariupol',
    color: '#3E3EFF',
    textAlign: 'center',
    zIndex: 1, // Поверх заливки батареи
  }
});

export default BatteryIndicator;
