import {Dimensions, Platform, StyleSheet} from 'react-native';

let screenWidth = Dimensions.get('screen').width;

// console.log('width in styles = ', screenWidth);

const platformStyles = {
  web: (wp, hp, fontSizeBig, fontSizeMd, theme) =>
    StyleSheet.create({
      profileContainer: {
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: '#FFF',
        maxWidth: 1080,
        flexDirection: 'column',
        // overflow: 'scroll',
        // height: hp('100%'),
        marginBottom: 30,
      },
      scrollableContainer: {
        alignItems: 'center',
        justifyContent: 'center',
        flex: 1,
        backgroundColor: theme.WHITE,
      },
      profilePhoto: {
        width: 182,
        height: 182,
        borderRadius: 50,
        // marginTop: 20,
        marginLeft: 50,
      },
      profileInfo: {
        height: 250,
        width: 800,
        flexDirection: 'row',
        borderTopRightRadius: 40,
        borderBottomRightRadius: 40,
        backgroundColor: theme.BLUE,
        alignSelf: 'flex-start',
        marginTop: 20,
        marginLeft: '-1%',
        alignItems: 'center',
      },
      nameText: {
        fontFamily: theme.FONT_FAMILY_MEDIUM,
        fontSize: 48,
        marginLeft: 20,
        marginTop: 80,
        lineHeight: 48,
        textAlign: 'center',
        color: theme.FONT_COLOR_WHITE,
      },
      nameTextEditing: {
        fontFamily: theme.FONT_FAMILY_MEDIUM,
        fontSize: 24,
        marginLeft: 20,
        marginTop: 0,
        marginBottom: 10,
        lineHeight: 24,
        textAlign: 'center',
        color: theme.FONT_COLOR_WHITE,
      },
      phoneText: {
        fontFamily: theme.FONT_FAMILY,
        fontSize: 16,
        textAlign: 'center',
        color: theme.FONT_COLOR_WHITE,
      },
      profileTextContainer: {
        width: 500,
        alignItems: 'flex-start',
        paddingLeft: 20,
      },
      profileText: {
        marginTop: 15,
        fontFamily: theme.FONT_FAMILY_MEDIUM,
        fontSize: 32,
        marginLeft: 20,
        lineHeight: 32,
        textAlign: 'center',
        color: theme.FONT_COLOR_WHITE,
      },
      infoBoxes: {
        marginTop: 67,
        rowGap: 20,
        // height: 820,
        justifyContent: 'center',
        alignItems: 'center',
      },
      infoBoxesRow: {
        flex: 1,
        flexDirection: 'row',
        flexWrap: 'wrap',
        gap: 20,
        justifyContent: 'center',
        alignItems: 'center',
      },
      infoBoxBlue: {
        width: wp('90%'),
        maxWidth: 360,
        height: 220,
        borderRadius: 20,
        backgroundColor: theme.BLUE,
      },
      infoBoxLightBlue: {
        width: wp('90%'),
        maxWidth: 360,
        height: 220,
        borderRadius: 20,
        backgroundColor: theme.LIGHT_BLUE,
      },
      infoBoxBlack: {
        width: wp('90%'),
        maxWidth: 360,
        height: 220,
        borderRadius: 20,
        backgroundColor: theme.BLACK,
      },
      statText: {
        width: 300,
        height: 90,
        left: 20,
        top: 32,
        fontFamily: theme.FONT_FAMILY,
        fontSize: 12,
        fontWeight: '300',
        lineHeight: 15,
        textAlign: 'left',
        color: theme.FONT_COLOR_WHITE,
      },
      buttonText: {
        fontFamily: theme.FONT_FAMILY,
        fontSize: 16,
        fontWeight: 'normal',
        lineHeight: 18,
        textAlign: 'center',
        color: theme.FONT_COLOR_WHITE,
      },
      statsContainer: {
        flexDirection: 'row',
        marginTop: 20,
        marginBottom: 20,
      },
      statContainer: {
        alignItems: 'center',
        flex: 1,
      },
      statCountContainer: {
        width: 'auto',
        height: 39,
        top: 37,
        left: 20,
      },
      statCount: {
        fontFamily: theme.FONT_FAMILY_BOLD,
        color: theme.FONT_COLOR_WHITE,
        fontSize: 32,
        lineHeight: 39,
      },
      statCountTxt: {
        fontFamily: theme.FONT_FAMILY,
        color: theme.FONT_COLOR_WHITE,
        fontWeight: 'normal',
        fontSize: 32,
        lineHeight: 39,
      },
      statTxt: {
        fontFamily: theme.FONT_FAMILY_MEDIUM,
        fontSize: 14,
        color: theme.FONT_COLOR_WHITE,
      },
      statLabel: {
        color: theme.FONT_COLOR_WHITE,
        fontFamily: theme.FONT_FAMILY,
        fontSize: 24,
        lineHeight: 29,
        left: 20,
        top: 20,
      },
      statImgRight: {
        width: 24,
        height: 24,
        top: 24,
        alignSelf: 'flex-end',
        marginRight: 5,
      },
      button: {
        width: 250,
        height: 39,
        marginTop: fontSizeMd,
        paddingVertical: 10,
        paddingHorisontal: 40,
        borderRadius: 10,
        gap: 10,
        backgroundColor: theme.BLUE,
        color: theme.FONT_COLOR_WHITE,
      },
      buttonBlack: {
        width: 250,
        height: 39,
        marginTop: fontSizeMd,
        paddingVertical: 10,
        paddingHorisontal: 40,
        borderRadius: 10,
        gap: 10,
        backgroundColor: theme.BLACK,
        color: theme.FONT_COLOR_WHITE,
      },
      buttonBlackBig: {
        width: 330,
        height: 45,
        marginTop: fontSizeMd,
        paddingVertical: 10,
        paddingHorisontal: 40,
        borderRadius: 10,
        gap: 10,
        backgroundColor: theme.BLACK,
        color: theme.FONT_COLOR_WHITE,
        alignSelf: 'flex-start',
        justifyContent: 'center',
        marginLeft: 50,
      },
      welcomeContainer: {
        flex: 1,
        width: wp('98%'),
        marginTop: 76,
        height: 'auto',
        justifyContent: 'center',
        alignItems: 'center',
        padding: 10,
        maxWidth: 1080,
      },
      welcome: {
        flex: 1,
        width: wp('80%'),
        maxWidth: 830,
        justifyContent: 'center',
      },
      welcomeText: {
        flex: 1,
        width: wp('90%'),
        maxWidth: 830,
        fontFamily: theme.FONT_FAMILY,
        fontSize: fontSizeBig,
        lineHeight: fontSizeBig,
        letterSpacing: 0,
        textAlign: 'left',
      },
      welcomeBlock1: {
        width: wp('80%'),
        maxWidth: 830,
        // flexWrap: 'wrap',
        marginTop: 28,
        fontFamily: theme.FONT_FAMILY,
        fontSize: 16,
        lineHeight: 19,
        textAlign: 'left',
      },
      welcomeBlock2: {
        width: wp('80%'),
        maxWidth: 830,
        marginTop: 52,
        fontFamily: theme.FONT_FAMILY,
        fontSize: fontSizeBig,
        lineHeight: fontSizeBig,
        textAlign: 'left',
      },
      advantages: {
        height: 'auto',
        width: wp('80%'),
        maxWidth: 830,
      },
      advantagesRow: {
        flexDirection: wp('100%') > 1000 ? 'row' : 'column',
        justifyContent: 'space-between',
        marginTop: 30,
      },
      advantagesCol: {
        marginTop: 30,
        maxWidth: wp('100%') > 1000 ? 360 : wp('80%'),
      },
      advantagesHead: {
        fontFamily: theme.FONT_FAMILY,
        fontSize: fontSizeMd,
        fontWeight: 'normal',
        lineHeight: fontSizeMd,
        textAlign: 'left',
      },
      advantagesContent: {
        marginTop: 28,
        fontFamily: theme.FONT_FAMILY,
        fontSize: 16,
        fontWeight: 'normal',
        lineHeight: 19,
        textAlign: 'left',
      },
      marketLinks: {
        flex: 1,
        width: wp('80%'),
        maxWidth: 830,
        marginTop: 30,
        marginBottom: 50,
      },
      editProfileHead: {
        // flex: 2,
        width: 505,
        height: 115,
        backgroundColor: theme.BLUE,
        borderRadius: 20,
        justifyContent: 'center',
        alignItems: 'center',
        // minHeight: 150,
      },
      formView: {
        flex: 3,
        width: wp('80%'),
        justifyContent: 'center',
        alignItems: 'center',
      },
      editProfileText: {
        fontFamily: theme.FONT_FAMILY,
        color: theme.FONT_COLOR_WHITE,
        fontSize: 40,
        fontWeight: '500',
        lineHeight: 40,
        textAlign: 'center',
        width: 385,
        height: 100,
        paddingVertical: 10,
      },
      editLinkText: {
        fontFamily: theme.FONT_FAMILY,
        color: theme.FONT_COLOR_WHITE,
        fontSize: 40,
        fontWeight: '500',
        lineHeight: 40,
        textAlign: 'center',
        width: 385,
        height: 130,
        paddingVertical: 10,
      },
      editShortDesc: {
        height: 135,
        width: 435,
        flexDirection: 'row',
        borderRadius: 20,
        backgroundColor: theme.BLUE,
        marginTop: 20,
        alignItems: 'center',
        // justifyContent: 'center',
      },
      editAvatar: {
        // flex: 1,
        borderRadius: 16,
        height: 90,
        width: 90,
        marginLeft: 50,
      },
      editName: {
        flex: 1,
        alignSelf: 'center',
        fontFamily: theme.FONT_FAMILY,
        fontSize: fontSizeBig,
        fontWeight: 'normal',
        lineHeight: 48,
        textAlign: 'center',
        width: 200,
        marginLeft: 20,
      },
      formControl: {
        rowGap: 12,
        marginTop: 39,
      },
      formLabel: {
        fontFamily: theme.FONT_FAMILY,
        fontSize: 12,
        fontWeight: 'normal',
        lineHeight: 15,
        textAlign: 'left',
        color: '#707070',
        margin: 0,
        paddingBottom: 3,
      },
      formInput: {
        width: 280,
        height: 42,
        borderRadius: 10,
        borderWidth: 2,
        padding: 10,
        color: theme.FONT_COLOR_BLACK,
        borderColor: theme.BLACK,
        borderStyle: 'solid',
        fontFamily: theme.FONT_FAMILY,
        fontSize: 20,
        fontWeight: 'normal',
        lineHeight: 19,
        textAlign: 'left',
      },
      refLinkInput: {
        width: 100,
        height: 42,
        borderRadius: 10,
        borderWidth: 1,
        padding: 10,
        color: theme.FONT_COLOR_BLACK,
        borderColor: theme.BLACK,
        borderStyle: 'solid',
        fontFamily: theme.FONT_FAMILY,
        fontSize: 16,
        fontWeight: 'normal',
        lineHeight: 19,
        textAlign: 'left',
      },
      personalLink: {
        height: 'auto',
        color: theme.FONT_COLOR_BLACK,
        fontFamily: theme.FONT_FAMILY,
        fontSize: 16,
        lineHeight: 19,
        paddingHorizontal: 10,
        marginTop: 15,
      },
      refLinkContainer: {
        height: 40,
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: '#FFF',
        borderWidth: 1,
        borderRadius: 5,
        borderColor: theme.BLACK,
        marginRight: 10,
      },
      refLinkText: {
        fontFamily: theme.FONT_FAMILY,
        fontSize: 14,
        // color: theme.FONT_COLOR_WHITE,
      },
      refLinkCaption: {
        fontFamily: theme.FONT_FAMILY,
        fontSize: 16,
        fontWeight: '400',
        lineHeight: 19,
        // paddingHorizontal
      },
      refLinkCaptionContainer: {
        height: 40,
        justifyContent: 'center',
        paddingRight: 10,
      },
      refLinkEdit: {
        width: 75,
        height: 39,
        paddingHorizontal: 10,
        paddingVertical: 12,
        borderRadius: 10,
        borderColor: theme.BLACK,
        backgroundColor: theme.BLACK,
        alignItems: 'center',
        justifyContent: 'center',
      },
      refLinkEditText: {
        fontFamily: theme.FONT_FAMILY,
        fontSize: 14,
        color: theme.FONT_COLOR_WHITE,
      },
      formSelect: {
        width: 251,
        height: 42,
        borderRadius: 10,
        borderWidth: 1,
        padding: 10,
        color: theme.FONT_COLOR_BLACK,
        borderColor: theme.BLACK,
        borderStyle: 'solid',
        backgroundColor: '#FFF',
      },
      formHelper: {},
      formStack: {
        width: 251,
        height: 60,
        marginTop: 12,
      },
      formBtnGroup: {
        width: 251,
        gap: 10,
        marginVertical: 20,
      },
      formBtn: {
        width: 114,
        height: 39,
        paddingHorizontal: 18,
        paddingVertical: 10,
        borderRadius: 10,
        gap: 10,
        backgroundColor: theme.BLACK,
        color: theme.FONT_COLOR_WHITE,
        fontFamily: theme.FONT_FAMILY,
        fontSize: 16,
        fontWeight: 'normal',
        lineHeight: 19,
        textAlign: 'left',
      },
      frmButtonText: {
        fontFamily: theme.FONT_FAMILY,
        color: theme.FONT_COLOR_WHITE,
        fontSize: 16,
        fontWeight: 'normal',
        lineHeight: 19,
        textAlign: 'center',
      },
      frmCheckbox: {
        backgroundColor: theme.BLACK,
        borderWidth: 1,
        borderColor: theme.BLACK,
        color: theme.FONT_COLOR_BLACK,
      },
      checkBoxTxt: {
        fontFamily: theme.FONT_FAMILY,
        fontSize: 12,
      },
      selectImgContainer: {
        marginTop: -75,
        marginLeft: 5,
        height: 20,
      },
      selectImage: {
        selfAlign: 'center',
        fontFamily: theme.FONT_FAMILY,
        fontSize: 16,
        fontWeight: 'normal',
        lineHeight: 19,
        textAlign: 'center',
        color: theme.FONT_COLOR_BLACK,
        width: 50,
      },
      selectImageInput: {
        fontFamily: theme.FONT_FAMILY,
        color: theme.FONT_COLOR_WHITE,
        fontSize: 12,
        backgroundColor: theme.BLACK,
        width: 70,
        cursor: 'pointer',
        borderRadius: 10,
        height: 20,
        alignItems: 'center',
        justifyContent: 'center',
        display: 'flex',
      },
      image: {
        width: 65,
        height: 65,
        justifyContent: 'center',
        alignItems: 'center',
        marginLeft: 20,
        // backgroundColor: theme.LIGHT_BLUE,
      },
      initials: {
        fontFamily: theme.FONT_FAMILY,
        fontSize: 24,
        fontWeight: 'bold',
        color: 'white',
      },
      image100: {
        width: 65,
        height: 65,
        justifyContent: 'center',
        alignItems: 'center',
      },
      shop_domain: {
        justifyContent: 'center',
      },
      shop_domain_text: {
        fontFamily: theme.FONT_FAMILY,
        fontSize: 16,
        lineHeight: 20,
      },
      infoContainer: {
        alignItems: 'center',
        justifyContent: 'center',
        gap: 5,
        // paddingHorisontal: 10,
      },
      shareContainer: {
        // flexDirection: 'row',
        // width: '30%',
        // maxWidth:
      },
      icon: {
        backgroundColor: '#000',
        borderRadius: 10,
        width: 20,
        height: 20,
        justifyContent: 'center',
        alignItems: 'center',
      },
      iconText: {
        color: '#fff',
        fontSize: 14,
      },
      tooltip: {
        flex: 1,
        position: 'absolute',
        top: 20,
        backgroundColor: '#fff',
        padding: 10,
        borderRadius: 5,
        shadowColor: '#000',
        shadowOpacity: 0.2,
        shadowRadius: 5,
        elevation: 2,
        zIndex: 100000,
      },
      linkDescriptionContainer: {
        // flex: 1,
        width: '80%',
        marginVertical: '5%',
      },
      linkDescriptionText: {
        fontFamily: theme.FONT_FAMILY,
        fontSize: 16,
      },
      addCardButton: {
        padding: 3,
        height: 20,
      },
      addCardText: {
        fontFamily: theme.FONT_FAMILY,
        fontSize: 14
      }
    }),
  android: (wp, hp, fontSizeBig, fontSizeMd, theme) =>
    StyleSheet.create({
      profileContainer: {
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: '#FFF',
        width: wp('100%'),
        flexDirection: 'column',
      },
      scrollableContainer: {
        alignItems: 'center',
        justifyContent: 'center',
        flex: 1,
      },
      profilePhoto: {
        width: 65,
        height: 65,
        borderRadius: 16,
        marginTop: 17,
      },
      nameText: {
        fontFamily: 'Inter',
        fontSize: 48,
        fontWeight: 'normal',
        marginTop: 24,
        lineHeight: 58,
        textAlign: 'center',
        color: '#000',
      },
      infoBoxes: {
        marginTop: 67,
        // columnGap: 20,
        // height: 820,
        justifyContent: 'center',
        alignItems: 'center',
      },
      infoBoxesRow: {
        // flex: 1,
        // flexDirection: 'row',
        flexWrap: 'wrap',
        // gap: 20,
        justifyContent: 'center',
        alignItems: 'center',
      },
      infoBoxesCol: {
        flexDirection: 'column',
        gap: 24,
        // width: 300,
      },
      infoBox: {
        marginTop: 20,
        width: wp('90%'),
        maxWidth: 360,
        height: 220,
        borderRadius: 20,
        backgroundColor: '#EAEAEA',
        color: '#000',
      },
      statText: {
        width: 300,
        height: 90,
        left: 20,
        top: 32,
        fontFamily: 'Inter',
        fontSize: 12,
        fontWeight: '300',
        lineHeight: 15,
        textAlign: 'left',
        color: '#000000',
      },
      buttonText: {
        fontFamily: 'Inter',
        fontSize: 16,
        fontWeight: 'normal',
        lineHeight: 18,
        textAlign: 'center',
        color: '#000000',
      },
      statsContainer: {
        flexDirection: 'row',
        marginTop: 20,
        marginBottom: 20,
      },
      statContainer: {
        alignItems: 'center',
        flex: 1,
      },
      statCountContainer: {
        width: 'auto',
        height: 39,
        top: 37,
        left: 20,
        color: '#000000',
      },
      statCount: {
        fontFamily: 'Inter',
        fontWeight: '900',
        fontSize: 32,
        lineHeight: 39,
        color: 'black',
      },
      statCountTxt: {
        fontFamily: 'Inter',
        fontWeight: 'normal',
        fontSize: 32,
        lineHeight: 39,
        color: 'black',
      },
      statTxt: {
        fontFamily: 'Inter',
        fontWeight: '700',
        fontSize: 14,
        color: '#000',
      },
      statLabel: {
        color: '#000',
        fontFamily: 'Inter',
        fontSize: 24,
        fontStyle: 'normal',
        fontWeight: 'normal',
        lineHeight: 29,
        left: 20,
        top: 20,
      },
      statImgRight: {
        width: 24,
        height: 24,
        // left: 328,
        top: 24,
        alignSelf: 'flex-end',
        marginRight: 5,
      },
      button: {
        width: 250,
        height: 39,
        marginTop: fontSizeMd,
        paddingVertical: 10,
        paddingHorisontal: 40,
        borderRadius: 10,
        gap: 10,
        backgroundColor: '#D9D9D9',
        color: '#000000',
      },
      welcomeContainer: {
        flex: 1,
        width: wp('98%'),
        marginTop: 76,
        height: 'auto',
        justifyContent: 'center',
        alignItems: 'center',
        padding: 10,
        maxWidth: 1080,
      },
      welcome: {
        flex: 1,
        width: wp('80%'),
        // alignItems: 'center',
        justifyContent: 'center',
      },
      welcomeText: {
        flex: 1,
        width: wp('90%'),
        // alignItems: 'center',
        fontFamily: 'Inter',
        // fontSize: 40,
        fontSize: fontSizeBig,
        fontWeight: 'normal',
        // lineHeight: 48,
        lineHeight: fontSizeBig,
        letterSpacing: 0,
        textAlign: 'left',
        color: '#000',
      },
      welcomeBlock1: {
        width: wp('80%'),
        // flexWrap: 'wrap',
        marginTop: 28,
        fontFamily: 'Inter',
        fontSize: 16,
        fontWeight: 'normal',
        lineHeight: 19,
        textAlign: 'left',
        color: '#000',
      },
      welcomeBlock2: {
        width: wp('80%'),
        marginTop: 52,
        fontFamily: 'Inter',
        fontSize: fontSizeBig,
        fontWeight: 'normal',
        lineHeight: fontSizeBig,
        textAlign: 'left',
        color: '#000',
      },
      advantages: {
        // flex: 1,
        // flexDirection: 'column',
        height: 'auto',
        // marginTop: 30,
        width: wp('80%'),
      },
      advantagesRow: {
        // flex: 1,
        // flexDirection: 'column',
        // gap: 30,
        // marginTop: 30,
      },
      advantagesCol: {
        marginTop: 30,
      },
      advantagesHead: {
        fontFamily: 'Inter',
        fontSize: fontSizeMd,
        fontWeight: 'normal',
        lineHeight: fontSizeMd,
        textAlign: 'left',
        color: '#000',
      },
      advantagesContent: {
        marginTop: 28,
        fontFamily: 'Inter',
        fontSize: 16,
        fontWeight: 'normal',
        lineHeight: 19,
        textAlign: 'left',
        color: '#000',
      },
      marketLinks: {
        flex: 1,
        width: wp('80%'),
        marginTop: 30,
        marginBottom: 50,
      },
      editProfileHead: {
        // flex: 2,
        width: wp('50%'),
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: 150,
      },
      formView: {
        flex: 3,
        width: wp('80%'),
        justifyContent: 'center',
        alignItems: 'center',
      },
      editProfileText: {
        fontFamily: 'Inter',
        fontSize: fontSizeBig,
        fontWeight: 'normal',
        lineHeight: 48,
        textAlign: 'center',
        height: 96,
        flexWrap: 'wrap',
        color: '#000',
      },
      editShortDesc: {
        marginTop: 20,
        flexDirection: 'column',
        flex: 1,
        alignItems: 'center',
      },
      editAvatar: {
        flex: 1,
        borderRadius: 100,
        height: 200,
        width: 200,
      },
      editName: {
        alignSelf: 'center',
        fontFamily: 'Inter',
        fontSize: fontSizeBig,
        fontWeight: 'normal',
        lineHeight: 48,
        textAlign: 'center',
        width: 200,
        color: '#000',
      },
      formControl: {
        rowGap: 12,
        marginTop: 39,
      },
      formLabel: {
        fontFamily: 'Inter',
        fontSize: 12,
        fontWeight: 'normal',
        lineHeight: 15,
        textAlign: 'left',
        color: '#707070',
        margin: 0,
        paddingBottom: 3,
      },
      formInput: {
        width: 280,
        height: 42,
        borderRadius: 10,
        borderWidth: 1,
        padding: 10,
        color: '#000000',
        borderColor: '#000000',
        borderStyle: 'solid',
        fontFamily: 'Inter',
        fontSize: 16,
        fontWeight: 'normal',
        lineHeight: 19,
        textAlign: 'left',
      },
      refLinkInput: {
        width: 100,
        height: 42,
        borderRadius: 10,
        borderWidth: 1,
        padding: 10,
        color: '#000000',
        borderColor: '#000000',
        borderStyle: 'solid',
        fontFamily: 'Inter',
        fontSize: 16,
        fontWeight: 'normal',
        lineHeight: 19,
        textAlign: 'left',
      },
      personalLink: {
        height: 42,
        color: '#000000',
        fontFamily: 'Inter',
        fontSize: 16,
        lineHeight: 19,
        paddingHorizontal: 10,
        marginTop: 15,
      },
      refLinkContainer: {
        height: 40,
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: '#f2e9e9',
        borderWidth: 1,
        borderRadius: 5,
        borderColor: '#D9D9D9',
        marginRight: 10,
      },
      refLinkText: {},
      refLinkCaption: {
        fontFamily: 'Inter',
        fontSize: 16,
        fontWeight: '600',
        lineHeight: 19,
        color: '#000',
      },
      refLinkCaptionContainer: {
        height: 40,
        justifyContent: 'center',
        paddingRight: 10,
      },
      refLinkEdit: {
        width: 75,
        height: 39,
        paddingHorizontal: 5,
        paddingVertical: 10,
        borderRadius: 10,
        backgroundColor: '#D9D9D9',
        alignItems: 'center',
        justifyContent: 'center',
      },
      formSelect: {
        width: 251,
        height: 42,
        borderRadius: 10,
        borderWidth: 1,
        padding: 10,
        color: '#000000',
        borderColor: '#000000',
        borderStyle: 'solid',
        backgroundColor: '#FFF',
      },
      formHelper: {},
      formStack: {
        width: 251,
        height: 60,
        marginTop: 12,
      },
      formBtnGroup: {
        width: 251,
        gap: 10,
        marginTop: 20,
        marginBottom: 60,
      },
      formBtn: {
        width: 114,
        height: 39,
        paddingHorizontal: 18,
        paddingVertical: 10,
        borderRadius: 10,
        gap: 10,
        backgroundColor: '#D9D9D9',
        fontFamily: 'Inter',
        color: '#000',
        fontSize: 16,
        fontWeight: 'normal',
        lineHeight: 19,
        textAlign: 'left',
      },
      frmButtonText: {
        fontFamily: 'Inter',
        fontSize: 16,
        fontWeight: 'normal',
        lineHeight: 19,
        textAlign: 'center',
        color: '#000000',
      },
      frmCheckbox: {
        backgroundColor: '#D9D9D9',
        borderWidth: 1,
        borderColor: '#000000',
        color: '#000000',
      },
      checkBoxTxt: {
        fontFamily: 'Inter',
        fontSize: 12,
      },
      selectImage: {
        // selfAlign: 'center',
        fontFamily: 'Inter',
        fontSize: 16,
        fontWeight: 'normal',
        lineHeight: 19,
        textAlign: 'center',
        color: '#707070',
        // borderWidth: 2,
      },
      selectImageInput: {
        fontFamily: 'Inter',
        fontSize: 16,
      },
      image: {
        width: 200,
        height: 200,
        justifyContent: 'center',
        alignItems: 'center',
      },
      initials: {
        fontSize: 48,
        fontWeight: 'bold',
        color: 'white',
      },
      image100: {
        width: 100,
        height: 100,
        justifyContent: 'center',
        alignItems: 'center',
      },
      shop_domain: {
        justifyContent: 'center',
      },
      shop_domain_text: {
        fontFamily: 'Inter',
        fontSize: 16,
        lineHeight: 20,
      },
      infoContainer: {
        alignItems: 'center',
        justifyContent: 'center',
        gap: 5,
        // paddingHorisontal: 10,
      },
      icon: {
        backgroundColor: '#000',
        borderRadius: 10,
        width: 20,
        height: 20,
        justifyContent: 'center',
        alignItems: 'center',
      },
      iconText: {
        color: '#fff',
        fontSize: 14,
      },
      tooltip: {
        flex: 1,
        position: 'absolute',
        top: 20,
        backgroundColor: '#fff',
        padding: 10,
        borderRadius: 5,
        shadowColor: '#000',
        shadowOpacity: 0.2,
        shadowRadius: 5,
        elevation: 2,
        zIndex: 100000,
      },
      linkDescriptionContainer: {
        // flex: 1,
        width: '80%',
        marginVertical: '5%',
      },
      linkDescriptionText: {
        fontFamily: theme.FONT_FAMILY,
        fontSize: 16,
      },
      addCardButton: {
        padding: 3,
        height: 20,
      },
      addCardText: {
        fontFamily: theme.FONT_FAMILY,
        fontSize: 14
      }
    }),
  ios: (wp, hp, fontSizeBig, fontSizeMd, theme) =>
    StyleSheet.create({
      profileContainer: {
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: '#FFF',
        width: wp('100%'),
        flexDirection: 'column',
      },
      scrollableContainer: {
        alignItems: 'center',
        justifyContent: 'center',
        flex: 1,
      },
      profilePhoto: {
        width: 200,
        height: 200,
        borderRadius: 100,
        marginTop: 87,
      },
      nameText: {
        fontFamily: 'Inter',
        fontSize: 48,
        fontWeight: 'normal',
        marginTop: 24,
        lineHeight: 58,
        textAlign: 'center',
        color: '#000',
      },
      infoBoxes: {
        marginTop: 67,
        // columnGap: 20,
        // height: 820,
        justifyContent: 'center',
        alignItems: 'center',
      },
      infoBoxesRow: {
        // flex: 1,
        // flexDirection: 'row',
        flexWrap: 'wrap',
        // gap: 20,
        justifyContent: 'center',
        alignItems: 'center',
      },
      infoBoxesCol: {
        flexDirection: 'column',
        gap: 24,
        // width: 300,
      },
      infoBox: {
        marginTop: 20,
        width: wp('90%'),
        maxWidth: 360,
        height: 220,
        borderRadius: 20,
        backgroundColor: '#EAEAEA',
        color: '#000',
      },
      statText: {
        width: 300,
        height: 90,
        left: 20,
        top: 32,
        fontFamily: 'Inter',
        fontSize: 12,
        fontWeight: '300',
        lineHeight: 15,
        textAlign: 'left',
        color: '#000000',
      },
      buttonText: {
        fontFamily: 'Inter',
        fontSize: 16,
        fontWeight: 'normal',
        lineHeight: 18,
        textAlign: 'center',
        color: '#000000',
      },
      statsContainer: {
        flexDirection: 'row',
        marginTop: 20,
        marginBottom: 20,
      },
      statContainer: {
        alignItems: 'center',
        flex: 1,
      },
      statCountContainer: {
        width: 'auto',
        height: 39,
        top: 37,
        left: 20,
        color: '#000000',
      },
      statCount: {
        fontFamily: 'Inter',
        fontWeight: '900',
        fontSize: 32,
        lineHeight: 39,
        color: 'black',
      },
      statCountTxt: {
        fontFamily: 'Inter',
        fontWeight: 'normal',
        fontSize: 32,
        lineHeight: 39,
        color: 'black',
      },
      statTxt: {
        fontFamily: 'Inter',
        fontWeight: '700',
        fontSize: 14,
        color: '#000',
      },
      statLabel: {
        color: '#000',
        fontFamily: 'Inter',
        fontSize: 24,
        fontStyle: 'normal',
        fontWeight: 'normal',
        lineHeight: 29,
        left: 20,
        top: 20,
      },
      statImgRight: {
        width: 24,
        height: 24,
        // left: 328,
        top: 24,
        alignSelf: 'flex-end',
        marginRight: 5,
      },
      button: {
        width: 250,
        height: 39,
        marginTop: fontSizeMd,
        paddingVertical: 10,
        paddingHorisontal: 40,
        borderRadius: 10,
        gap: 10,
        backgroundColor: '#D9D9D9',
        color: '#000000',
      },
      welcomeContainer: {
        flex: 1,
        width: wp('98%'),
        marginTop: 76,
        height: 'auto',
        justifyContent: 'center',
        alignItems: 'center',
        padding: 10,
        maxWidth: 1080,
      },
      welcome: {
        flex: 1,
        width: wp('80%'),
        // alignItems: 'center',
        justifyContent: 'center',
      },
      welcomeText: {
        flex: 1,
        width: wp('90%'),
        // alignItems: 'center',
        fontFamily: 'Inter',
        // fontSize: 40,
        fontSize: fontSizeBig,
        fontWeight: 'normal',
        // lineHeight: 48,
        lineHeight: fontSizeBig,
        letterSpacing: 0,
        textAlign: 'left',
        color: '#000',
      },
      welcomeBlock1: {
        width: wp('80%'),
        // flexWrap: 'wrap',
        marginTop: 28,
        fontFamily: 'Inter',
        fontSize: 16,
        fontWeight: 'normal',
        lineHeight: 19,
        textAlign: 'left',
        color: '#000',
      },
      welcomeBlock2: {
        width: wp('80%'),
        marginTop: 52,
        fontFamily: 'Inter',
        fontSize: fontSizeBig,
        fontWeight: 'normal',
        lineHeight: fontSizeBig,
        textAlign: 'left',
        color: '#000',
      },
      advantages: {
        // flex: 1,
        // flexDirection: 'column',
        height: 'auto',
        // marginTop: 30,
        width: wp('80%'),
      },
      advantagesRow: {
        // flex: 1,
        // flexDirection: 'column',
        // gap: 30,
        // marginTop: 30,
      },
      advantagesCol: {
        marginTop: 30,
      },
      advantagesHead: {
        fontFamily: 'Inter',
        fontSize: fontSizeMd,
        fontWeight: 'normal',
        lineHeight: fontSizeMd,
        textAlign: 'left',
        color: '#000',
      },
      advantagesContent: {
        marginTop: 28,
        fontFamily: 'Inter',
        fontSize: 16,
        fontWeight: 'normal',
        lineHeight: 19,
        textAlign: 'left',
        color: '#000',
      },
      marketLinks: {
        flex: 1,
        width: wp('80%'),
        marginTop: 30,
        marginBottom: 50,
      },
      editProfileHead: {
        // flex: 2,
        width: wp('80%'),
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: 150,
      },
      formView: {
        flex: 3,
        width: wp('80%'),
        justifyContent: 'center',
        alignItems: 'center',
      },
      editProfileText: {
        fontFamily: 'Inter',
        fontSize: fontSizeBig,
        fontWeight: 'normal',
        lineHeight: 48,
        textAlign: 'center',
        height: 96,
        flexWrap: 'wrap',
        color: '#000',
      },
      editShortDesc: {
        marginTop: 20,
        flexDirection: 'column',
        flex: 1,
        alignItems: 'center',
      },
      editAvatar: {
        flex: 1,
        borderRadius: 100,
        height: 200,
        width: 200,
      },
      editName: {
        alignSelf: 'center',
        fontFamily: 'Inter',
        fontSize: fontSizeBig,
        fontWeight: 'normal',
        lineHeight: 48,
        textAlign: 'center',
        width: 200,
        color: '#000',
      },
      formControl: {
        rowGap: 12,
        marginTop: 39,
      },
      formLabel: {
        fontFamily: 'Inter',
        fontSize: 12,
        fontWeight: 'normal',
        lineHeight: 15,
        textAlign: 'left',
        color: '#707070',
        margin: 0,
        paddingBottom: 3,
      },
      formInput: {
        width: 280,
        height: 42,
        borderRadius: 10,
        borderWidth: 1,
        padding: 10,
        color: '#000000',
        borderColor: '#000000',
        borderStyle: 'solid',
        fontFamily: 'Inter',
        fontSize: 16,
        fontWeight: 'normal',
        lineHeight: 19,
        textAlign: 'left',
      },
      refLinkInput: {
        width: 100,
        height: 42,
        borderRadius: 10,
        borderWidth: 1,
        padding: 10,
        color: '#000000',
        borderColor: '#000000',
        borderStyle: 'solid',
        fontFamily: 'Inter',
        fontSize: 16,
        fontWeight: 'normal',
        lineHeight: 19,
        textAlign: 'left',
      },
      personalLink: {
        height: 42,
        color: '#000000',
        fontFamily: 'Inter',
        fontSize: 16,
        lineHeight: 19,
        paddingHorizontal: 10,
      },
      refLinkContainer: {
        height: 40,
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: '#f2e9e9',
        borderWidth: 1,
        borderRadius: 5,
        borderColor: '#D9D9D9',
        marginRight: 10,
      },
      refLinkText: {},
      refLinkCaption: {
        fontFamily: 'Inter',
        fontSize: 16,
        fontWeight: '600',
        lineHeight: 19,
        color: '#000',
      },
      refLinkCaptionContainer: {
        height: 40,
        justifyContent: 'center',
        paddingRight: 10,
      },
      refLinkEdit: {
        width: 75,
        height: 39,
        paddingHorizontal: 5,
        paddingVertical: 10,
        borderRadius: 10,
        backgroundColor: '#D9D9D9',
        alignItems: 'center',
        justifyContent: 'center',
      },
      formSelect: {
        width: 251,
        height: 42,
        borderRadius: 10,
        borderWidth: 1,
        padding: 10,
        color: '#000000',
        borderColor: '#000000',
        borderStyle: 'solid',
        backgroundColor: '#FFF',
      },
      formHelper: {},
      formStack: {
        width: 251,
        height: 60,
        marginTop: 12,
      },
      formBtnGroup: {
        width: 251,
        gap: 10,
        marginBottom: '90%',
      },
      formBtn: {
        width: 114,
        height: 39,
        paddingHorizontal: 18,
        paddingVertical: 10,
        borderRadius: 10,
        gap: 10,
        backgroundColor: '#D9D9D9',
        fontFamily: 'Inter',
        color: '#000',
        fontSize: 16,
        fontWeight: 'normal',
        lineHeight: 19,
        textAlign: 'left',
      },
      frmButtonText: {
        fontFamily: 'Inter',
        fontSize: 16,
        fontWeight: 'normal',
        lineHeight: 19,
        textAlign: 'center',
        color: '#000000',
      },
      frmCheckbox: {
        backgroundColor: '#D9D9D9',
        borderWidth: 1,
        borderColor: '#000000',
        color: '#000000',
      },
      checkBoxTxt: {
        fontFamily: 'Inter',
        fontSize: 12,
      },
      selectImage: {
        // selfAlign: 'center',
        fontFamily: 'Inter',
        fontSize: 16,
        fontWeight: 'normal',
        lineHeight: 19,
        textAlign: 'center',
        color: '#707070',
        // borderWidth: 2,
      },
      selectImageInput: {
        fontFamily: 'Inter',
        fontSize: 16,
      },
      image: {
        width: 65,
        height: 65,
        justifyContent: 'center',
        alignItems: 'center',
        // marginLeft: 15,
      },
      initials: {
        fontSize: 48,
        fontWeight: 'bold',
        color: 'white',
      },
      image100: {
        width: 100,
        height: 100,
        justifyContent: 'center',
        alignItems: 'center',
      },
      shop_domain: {
        justifyContent: 'center',
      },
      shop_domain_text: {
        fontFamily: 'Inter',
        fontSize: 16,
        lineHeight: 20,
      },
      infoContainer: {
        alignItems: 'center',
        justifyContent: 'center',
        gap: 5,
        // paddingHorisontal: 10,
      },
      linkDescriptionContainer: {
        // flex: 1,
        width: '80%',
        marginVertical: '5%',
      },
      linkDescriptionText: {
        fontFamily: theme.FONT_FAMILY,
        fontSize: 16,
      },
      icon: {
        backgroundColor: '#000',
        borderRadius: 10,
        width: 20,
        height: 20,
        justifyContent: 'center',
        alignItems: 'center',
      },
      iconText: {
        color: '#fff',
        fontSize: 14,
      },
      tooltip: {
        flex: 1,
        position: 'absolute',
        top: 20,
        backgroundColor: '#fff',
        padding: 10,
        borderRadius: 5,
        shadowColor: '#000',
        shadowOpacity: 0.2,
        shadowRadius: 5,
        elevation: 2,
        zIndex: 100000,
      },
      addCardText: {
        fontFamily: theme.FONT_FAMILY,
        fontSize: 14
      }
    }),
};

export default platformStyles[Platform.OS];
