import React, {useContext, useEffect, useState} from 'react';
import {
  Image,
  Pressable,
  Platform,
  TouchableOpacity,
  TouchableWithoutFeedback,
  StyleSheet,
  Modal,
} from 'react-native';
import {Text, Center, HStack, View, Divider} from 'native-base';
import { BackHandler } from 'react-native';
import { useNavigation } from '@react-navigation/native';
import {
  widthPercentageToDP as wp,
  heightPercentageToDP as hp,
} from 'react-native-responsive-screen';
import {Picker} from '@react-native-picker/picker';
import Icon from 'react-native-vector-icons/FontAwesome';

import AxiosInstance from '../../components/common/axiosInstance';
import {AuthContext} from '../../components/AuthProvider';
import Breadcrumbs from '../../components/common/Breadcrumbs';
import Empty from './Empty';

import XWhite from '../../assets/img/xwhite.svg';
import Bars from '../../assets/img/bars.svg';

import {default as headerStylesMobile} from '../../styles/header_mobile';
import {default as headerStylesDesktop} from '../../styles/header';
import {default as commonStylesF} from '../../styles/common';
import {default as commonStylesMobileF} from '../../styles/common_mobile';
import {default as themeG} from '../../config/theme_config';

let ToastAndroid;
if (Platform.OS === 'android') {
  ToastAndroid = require('react-native').ToastAndroid;
}
// const screenWidth = Dimensions.get('screen').width;
// const headerStyles = screenWidth < 1080 ? headerStylesMobile(wp) : headerStylesDesktop;

const saveLanguage = async (lang, user, logout) => {
  if (!user) {
    return false;
  }

  if (user.lang === lang) {
    return true;
  }
  const api = AxiosInstance({user, logout});
  await api(`/api/v1/users/${user.id}`, {
    method: 'PUT',
    mode: 'cors',
    data: JSON.stringify({
      lang: lang,
    }),
  });
}

const DropdownMenu = ({navigation}) => {
  const {screenWidth, theme, t, logout} = useContext(AuthContext);
  const [menuVisible, setMenuVisible] = useState(false);
  const commonStyles =
    screenWidth < 1080
      ? commonStylesMobileF(wp, hp, theme)
      : commonStylesF(wp, hp, theme);
  const headerStyles =
    screenWidth < 1080
      ? headerStylesMobile(wp, theme)
      : headerStylesDesktop(theme);

  const toggleMenu = () => {
    setMenuVisible(!menuVisible);
  };

  return (
    <View style={headerStyles.headerLinksContainer}>
      <TouchableOpacity onPress={toggleMenu} style={{...styles.menuButton}}>
        {menuVisible ? (
          Platform.OS === 'web' ? (
            <Image source={XWhite} style={styles.xwhite} />
          ) : (
            <XWhite size={40} />
          )
        ) : Platform.OS === 'web' ? (
          <Image source={Bars} style={styles.bars} />
        ) : (
          <Bars size={40} />
        )}
      </TouchableOpacity>
      <Modal
        style={{width: wp('100%')}}
        animationType="fade"
        transparent={true}
        visible={menuVisible}
        onRequestClose={toggleMenu}>
        <TouchableWithoutFeedback onPress={toggleMenu}>
          <View style={styles.modalView}>
            <View
              style={{
                width: wp('100%'),
                height: 45,
                backgroundColor: theme.BLUE,
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'space-between',
                marginBottom: 30,
              }}>
              <View style={headerStyles.headerLogoContainer}>
                <Text style={headerStyles.headerLogoText}>{t('cashback')}</Text>
              </View>
              <Pressable onPress={toggleMenu} style={styles.menuButton}>
                {Platform.OS === 'web' ? (
                  <Image source={XWhite} style={styles.xwhite} />
                ) : (
                  <XWhite style={styles.xwhite} />
                )}
              </Pressable>
            </View>
            {/* <Pressable
              style={styles.menuItem}
              onPress={() => {
                toggleMenu();
                navigation.navigate('Invitations');
              }}>
              <Text style={headerStyles.menuItemText}>{t('invitations')}</Text>
            </Pressable> */}
            <Pressable
              style={styles.menuItem}
              onPress={() => {
                toggleMenu();
                navigation.navigate('MyOrders');
              }}>
              <Text style={headerStyles.menuItemText}>{t('my_orders')}</Text>
            </Pressable>
            <Pressable
              style={styles.menuItem}
              onPress={() => {
                toggleMenu();
                navigation.navigate('Moneybox');
              }}>
              <Text style={headerStyles.menuItemText}>{t('moneybox')}</Text>
            </Pressable>
            <Pressable
              style={styles.menuItem}
              onPress={() => {
                toggleMenu();
                navigation.navigate('Balance');
              }}>
              <Text style={headerStyles.menuItemText}>{t('balance')}</Text>
            </Pressable>
            {/* <Pressable
              style={styles.menuItem}
              onPress={() => {
                toggleMenu();
                navigation.navigate('History');
              }}>
              <Text style={headerStyles.menuItemText}>{t('history')}</Text>
            </Pressable> */}
            <View style={{...commonStyles.btnGroup, justifyContent: 'center'}}>
              <Pressable
                style={commonStyles.btnCommon}
                onPress={() => logout()}>
                <Text style={commonStyles.btnCommonTxt}>{t('log_out')}</Text>
              </Pressable>
              <Pressable
                style={commonStyles.btnCommon}
                onPress={() => {
                  toggleMenu();
                  navigation.navigate('Profile');
                }}>
                <Text style={commonStyles.btnCommonTxt}>{t('profile')}</Text>
              </Pressable>
            </View>
          </View>
        </TouchableWithoutFeedback>
      </Modal>
    </View>
  );
};

const HeaderWebSmall = ({navigation, route}) => {
  const {user, logout, goToAdmin, t, i18n, screenWidth, theme} =
    useContext(AuthContext);
  const currentLanguage = i18n.language;
  const headerStyles =
    screenWidth < 1080
      ? headerStylesMobile(wp, theme)
      : headerStylesDesktop(wp, theme);

  const changeLanguage = language => {
    i18n.changeLanguage(language);
    saveLanguage(language, user, logout);
  };

  return (
    <HStack style={headerStyles.headerContainer}>
      <View style={headerStyles.headerLogoContainer}>
        <Pressable
          // onPress={() => navigation.navigate('FirstPage')}
          onPress={() => navigation.navigate('Profile')} // TODO: сделать ссылку на главную страницу, пока не работает
        >
          <Text style={headerStyles.headerLogoText}>{t('cashback')}</Text>
        </Pressable>
      </View>
      <HStack style={headerStyles.nagationBox}>
        <HStack style={headerStyles.langButtons}>
          <Picker
            selectedValue={currentLanguage}
            style={{
              height: 25,
              width: 60,
              border: 'none',
              backgroundColor: '#FFF',
            }}
            onValueChange={(itemValue, itemIndex) => changeLanguage(itemValue)}>
            <Picker.Item style={headerStyles.langButton} label="UA" value="ua" />
            <Picker.Item label="RU" value="ru" />
            <Picker.Item label="EN" value="en" />
          </Picker>
        </HStack>
        {user && <DropdownMenu navigation={navigation} />}
        {/* {user && (
        <Pressable
          onPress={() => {
            navigation
              ? navigation.navigate('Profile')
              : alert(t('please_login'));
          }}>
          <Image
            style={headerStyles.headerUser}
            source={require('../../assets/img/si_User_alt.png')}
          />
        </Pressable>
        )}
        {user && user.isAdmin && (
          <Pressable onPress={() => goToAdmin()}>
            <Text>Админ</Text>
          </Pressable>
        )}
        {user && (
          <Pressable onPress={() => logout()}>
            <Icon name="sign-out" size={35} color="grey" />
          </Pressable>
        )} */}
      </HStack>
    </HStack>
  );
};

const HeaderWebBig = ({navigation, route}) => {
  const {user, logout, goToAdmin, t, i18n, screenWidth, theme} =
    useContext(AuthContext);
  const currentRoute = route && route.name ? route.name : '';
  const currentLanguage = i18n.language;
  const headerStyles =
    screenWidth < 1080
      ? headerStylesMobile(wp, theme)
      : headerStylesDesktop(theme);

  const changeLanguage = language => {
    i18n.changeLanguage(language);
    if (user) {
      saveLanguage(language, user, logout);
    }
  };

  return (
    <>
      <Center>
        <HStack style={headerStyles.headerContainer}>
          <View style={headerStyles.headerLogoContainer}>
            <Pressable
              onPress={() => navigation.navigate('Profile')}
            >
              <Text style={headerStyles.headerLogoText}>{t('cashback')}</Text>
            </Pressable>
          </View>

          {user ? (
            <HStack style={headerStyles.headerLinksContainer}>
              {/* <Pressable
                onPress={() => {
                  navigation
                    ? navigation.navigate('Invitations')
                    : alert(t('please_login'));
                }}>
                <Text
                  style={[
                    headerStyles.statLabel,
                    currentRoute === 'Invitations' && headerStyles.selected,
                  ]}>
                  {t('invitations')}
                </Text>
              </Pressable> */}
              <Pressable
                onPress={() => {
                  navigation
                    ? navigation.navigate('MyOrders')
                    : alert(t('please_login'));
                }}>
                <Text
                  style={[
                    headerStyles.statLabel,
                    currentRoute === 'MyOrders' && headerStyles.selected,
                  ]}>
                  {t('my_orders')}
                </Text>
              </Pressable>
              <Pressable
                onPress={() => {
                  navigation
                    ? navigation.navigate('Moneybox')
                    : alert(t('please_login'));
                }}>
                <Text
                  style={[
                    headerStyles.statLabel,
                    currentRoute === 'Moneybox' && headerStyles.selected,
                  ]}>
                  {t('moneybox')}
                </Text>
              </Pressable>

              {/* <Pressable
              onPress={() => {
                navigation
                  ? navigation.navigate('Transactions')
                  : alert(t('please_login'));
              }}>
              <Text style={headerStyles.statLabel}>Транзакции</Text>
            </Pressable> */}
              <Pressable
                onPress={() => {
                  navigation
                    ? navigation.navigate('Balance')
                    : alert(t('please_login'));
                }}>
                <Text
                  style={[
                    headerStyles.statLabel,
                    currentRoute === 'Balance' && headerStyles.selected,
                  ]}>
                  {t('transactions')}
                </Text>
              </Pressable>

              {/* <Pressable
                onPress={() => {
                  navigation
                    ? navigation.navigate('History')
                    : alert(t('please_login'));
                }}>
                <Text
                  style={[
                    headerStyles.statLabel,
                    currentRoute === 'History' && headerStyles.selected,
                  ]}>
                  {t('history')}
                </Text>
              </Pressable> */}
              {/* <Pressable
              onPress={() => {
                navigation
                  ? navigation.navigate('Withdraw')
                  : alert(t('please_login'));
              }}>
              <Text style={[headerStyles.statLabel, (currentRoute === 'Withdraw')  && headerStyles.selected]}>{t('withdraws')}</Text>
            </Pressable> */}
            </HStack>
          ) : (
            <HStack style={headerStyles.headerLinksContainer}></HStack>
          )}
          <HStack style={headerStyles.langButtons}>
            <Picker
              selectedValue={currentLanguage}
              style={{
                height: 35,
                width: 60,
                borderWidth: 1,
                borderColor: '#000000',
                backgroundColor: '#FFF',
              }}
              onValueChange={(itemValue, itemIndex) =>
                changeLanguage(itemValue)
              }>
              <Picker.Item label="UA" value="ua" />
              <Picker.Item label="RU" value="ru" />
              <Picker.Item label="EN" value="en" />
            </Picker>
          </HStack>
          {user && (
            <HStack style={headerStyles.nagationBox}>
              <Pressable
                onPress={() => {
                  navigation
                    ? navigation.navigate('Profile')
                    : alert(t('please_login'));
                }}>
                <Text style={headerStyles.myProfileButton}>
                  {t('my_profile_text')}
                </Text>
              </Pressable>
              {user.isAdmin ? (
                <Pressable onPress={() => goToAdmin()}>
                  <Text>Админ</Text>
                </Pressable>
              ) : (
                <Pressable onPress={() => logout()}>
                  <Icon name="sign-out" size={35} color="black" />
                </Pressable>
              )}
            </HStack>
          )}
        </HStack>
      </Center>
      {route && route.name === 'Trios' && <Breadcrumbs />}
    </>
  );
};

const HeaderWeb = ({navigation, route}) => {
  const {screenWidth} = useContext(AuthContext);
  if (screenWidth < 1080) {
    return <HeaderWebSmall navigation={navigation} route={route} />;
  } else {
    return <HeaderWebBig navigation={navigation} route={route} />;
  }
};

const HeaderMobile = ({navigation, route, hideLogoutButton}) => {
  const {user, t, i18n, screenWidth, theme, browseHistory, goBack, lastRoute} =
    useContext(AuthContext);
  const currentLanguage = i18n.language;
  const headerStyles =
    screenWidth < 1080
      ? headerStylesMobile(wp, theme)
      : headerStylesDesktop(wp, theme);

  const changeLanguage = language => {
    i18n.changeLanguage(language);
    saveLanguage(language);
  };

  const _navigation = useNavigation();

  useEffect(() => {
    let lastBackPressed = 0;
    const backHandler = BackHandler.addEventListener('hardwareBackPress', () => {
      if (browseHistory.length > 1) {
        console.log('browseHistory', browseHistory, lastRoute());
        _navigation.navigate(lastRoute());  // Используйте последний маршрут из истории
        goBack();  // Удалите последний маршрут из истории
        return true;
      } else if (_navigation.canGoBack()) {
        // console.log('canGoBack');
        _navigation.goBack();
        return true; // предотвратить дефолтное поведение
      } else {
        if (lastBackPressed && lastBackPressed + 2000 >= Date.now()) {
          BackHandler.exitApp(); // выход из приложения при двойном нажатии
          return false;
        }
        lastBackPressed = Date.now();
        if (Platform.OS !== 'web' && ToastAndroid) {
          ToastAndroid.show(t('click_again'), ToastAndroid.SHORT);
        } else {
          console.log('ToastAndroin not available for web');
        }
        return true; // предотвратить дефолтное поведение
      }
    });

    return () => backHandler.remove();
  });

  return (
    <HStack style={headerStyles.headerContainer}>
      <View style={headerStyles.headerLogoContainer}>
        <Pressable
          onPress={() => navigation.navigate('FirstPage')}
        >
          <Text style={headerStyles.headerLogoText}>{t('moneybox')}</Text>
        </Pressable>
      </View>
      <HStack style={{...headerStyles.nagationBox, alignItems: 'center'}}>
        <HStack style={{...headerStyles.langButtons, width: 120, height: 55}}>
          <Picker
            selectedValue={currentLanguage}
            style={{width: 100, height: 25, backgroundColor: theme.BLUE}}
            onValueChange={(itemValue, itemIndex) => {
              console.log(itemValue);
              changeLanguage(itemValue);
            }}>
            <Picker.Item
              style={headerStyles.langButton}
              label="UA"
              value="ua"
            />
            <Picker.Item
              style={headerStyles.langButton}
              label="RU"
              value="ru"
            />
            <Picker.Item
              style={headerStyles.langButton}
              label="EN"
              value="en"
            />
          </Picker>
        </HStack>
        {user && <DropdownMenu navigation={navigation} />}
        {/*
        {(user && user.isAdmin) && (
          <Pressable onPress={() => goToAdmin()}>
            <Text>Админ</Text>
          </Pressable>
        )}
     */}
      </HStack>
    </HStack>
  );
};

const styles = StyleSheet.create({
  menuButton: {
    margin: 10,
    zIndex: 1001, // Чтобы кнопка была поверх меню
  },
  menu: {
    backgroundColor: 'lightgrey',
    // position: 'absolute', // Абсолютное позиционирование для анимации
    top: 0,
    left: 0,
    bottom: 0,
    overflow: 'hidden',
  },
  menuItem: {
    // height: 40,
    paddingHorizontal: 20,
    paddingVertical: 10,
  },
  modalView: {
    // paddingTop: 20,
    // top: 45,
    width: '100%',
    height: '100%',
    backgroundColor: 'white',
    alignItems: 'left',
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 4,
    elevation: 5,
  },
  xwhite: {
    width: 30,
    height: 30,
  },
  bars: {
    width: 30,
    height: 30,
  },
});

const Header = Platform.OS === 'web' ? HeaderWeb : HeaderMobile;
export default Header;
