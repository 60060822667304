import React from 'react';
import {Platform, StyleSheet} from 'react-native';

const platformStyles = {
  web: (wp, hp, theme) =>
    StyleSheet.create({
      modalContainer: {
        // flex: 1,
        position: 'fixed',
        top: 0,
        alignItems: 'center',
        alignSelf: 'center',
        width: wp('100%'),
        maxWidth: 1080,
        height: hp('100%'),
        backgroundColor: '#0000001A',
        zIndex: 10,
        justifyContent: 'center',
      },
      modalContent: {
        // top: hp('2%'),
        width: wp('90%'),
        maxWidth: 392,
        height: 307,
        borderRadius: 40,
        backgroundColor: theme.WHITE,
        // justifyContent: 'center',
        // alignItems: 'center',
        zIndex: 11,
      },
      modalHead: {
        alignItems: 'flex-end',
        height: 52,
      },
      modalBody: {
        width: 267,
        height: 222,
        alignItems: 'center',
        // justifyContent: 'center',
        alignSelf: 'center',
        gap: 23,
      },
      modalCaption: {
        width: 180,
        height: 48,
        fontFamily: theme.FONT_FAMILY_MEDIUM,
        color: theme.FONT_COLOR_BLACK,
        fontSize: 40,
        fontWeight: 'normal',
        lineHeight: 48,
        textAlign: 'left',
        marginTop: -10,
      },
      inputContainer: {
        width: 251,
        height: 60,
      },
      sumLabel: {
        fontFamily: theme.FONT_FAMILY,
        color: theme.FONT_COLOR_BLUE,
        fontSize: 12,
        fontWeight: 'normal',
        lineHeight: 15,
        textAlign: 'left',
      },
      sumInput: {
        width: 250,
        height: 42,
        borderWidth: 1,
        borderRadius: 10,
        borderColor: theme.BLACK,
        padding: 10,
        fontFamily: theme.FONT_FAMILY,
        fontSize: 16,
        fontWeight: 'normal',
        lineHeight: 19,
        textAlign: 'left',
      },
      modalBtnGroup: {
        gap: 10,
      },
      modalBtn: {
        width: 114,
        height: 39,
        borderRadius: 10,
        backgroundColor: theme.BLACK,
        paddingVertical: 10,
        paddingHorizontal: 20,
        fontFamily: theme.FONT_FAMILY,
        fontSize: 16,
        fontWeight: 'normal',
        lineHeight: 19,
        textAlign: 'center',
        alignItems: 'center',
        justifyContent: 'center',
      },
      iconClose: {
        width: 20,
        height: 20,
        marginTop: 32,
        marginRight: 32,
      },
      balanceFooterContainer: {
        flex: 1,
      },
      balanceHead: {
        marginTop: 10,
        width: 505,
        height: 115,
        backgroundColor: theme.BLUE,
        borderRadius: 20,
        alignItems: 'center',
        justifyContent: 'center',
      },
      contentHeadText: {
        fontFamily: theme.FONT_FAMILY,
        color: theme.FONT_COLOR_WHITE,
        fontSize: 40,
        fontWeight: 'normal',
        lineHeight: 48,
        textAlign: 'left',
        height: 48,
      },
      availableBalance: {
        width: 255,
        height: 180,
        marginTop: 28,
        paddingHorizontal: 20,
        paddingVertical: 10,
        borderRadius: 10,
        gap: 100,
        backgroundColor: theme.BLUE,
        alignItems: 'flex-start',
        justifyContent: 'center',
      },
      availableText: {
        fontFamily: theme.FONT_FAMILY_MEDIUM,
        color: theme.FONT_COLOR_WHITE,
        fontSize: 20,
        lineHeight: 20,
        textAlign: 'left',
      },
      availableText1: {
        fontFamily: theme.FONT_FAMILY_MEDIUM,
        color: theme.FONT_COLOR_WHITE,
        fontSize: 20,
        fontWeight: 'normal',
        lineHeight: 20,
        textAlign: 'left',
      },
      balanceHeader: {
        width: 600,
        height: 72,
        alignItems: 'center',
        justifyContent: 'center',
        // gap: 60,
        borderBottomWidth: 1,
        borderStyle: 'solid',
        borderBottomColor: '#D9D9D9',
        marginTop: 41,
        marginBottom: 8,
      },
      balanceHeaderTxt: {
        width: 148,
        fontFamily: theme.FONT_FAMILY,
        fontSize: 16,
        fontWeight: 'normal',
        lineHeight: 19,
        textAlign: 'center',
      },
      balanceRow: {
        width: 600,
        height: 72,
        alignItems: 'center',
        justifyContent: 'center',
        border: 1,
        borderRadius: 10,
        borderStyle: 'solid',
        // borderColor: '#D9D9D9',
        marginTop: 12,
      },
      balanceRowText: {
        width: '25%',
        textAlign: 'center',
        fontFamily: theme.FONT_FAMILY,
        color: theme.FONT_COLOR_WHITE,
        fontSize: 16,
        fontWeight: 'normal',
        lineHeight: 19,
      },
      btnGroup: {
        marginTop: 31,
        gap: 5,
        marginBottom: 32,
        flex: 1,
      },
      balanceBtnWithdraw: {
        width: 180,
        height: 39,
        paddingHorizontal: 20,
        paddingVertical: 10,
        borderRadius: 10,
        backgroundColor: theme.BLACK,
        alignItems: 'center',
        justifyContent: 'center',
        fontFamily: theme.FONT_FAMILY,
        fontSize: 16,
        fontWeight: 'normal',
        lineHeight: 19,
        textAlign: 'left',
      },
      btnTextWhite: {
        fontFamily: theme.FONT_FAMILY,
        color: theme.FONT_COLOR_WHITE,
        fontSize: 16,
      },
      btnProfile: {
        width: 160,
        height: 39,
        paddingHorizontal: 10,
        paddingVertical: 10,
        borderWidth: 1,
        borderStyle: 'solid',
        borderRadius: 10,
        borderColor: '#000000',
        backgroundColor: '#FFF',
        color: '#000000',
        alignItems: 'center',
        justifyContent: 'center',
      },
      divider: {
        flex: 1,
      },
      error: {
        height: 30,
        color: 'red',
      },
      cashbackStats: {
        marginTop: 32,
        gap: 12,
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'row',
        flexWrap: 'wrap',
      },
      cashbackStatBlack: {
        width: 184,
        height: 166,
        borderRadius: 20,
        backgroundColor: theme.BLACK,
      },
      cashbackStatBlue: {
        width: 184,
        height: 166,
        borderRadius: 20,
        backgroundColor: theme.BLUE,
      },
      cashbackStatTxt1: {
        width: 160,
        height: 45,
        marginTop: 20,
        marginLeft: 20,
        fontFamily: theme.FONT_FAMILY_MEDIUM,
        color: theme.FONT_COLOR_WHITE,
        fontSize: 16,
        lineHeight: 16,
        textAlign: 'left',
      },
      cashbackStatTxt2: {
        fontFamily: theme.FONT_FAMILY_MEDIUM,
        color: theme.FONT_COLOR_WHITE,
        height: 39,
        marginTop: 10,
        marginLeft: 20,
        fontSize: 18,
        lineHeight: 18,
        textAlign: 'left',
      },
      cashbackStatTxt3: {
        fontFamily: theme.FONT_FAMILY_MEDIUM,
        color: theme.FONT_COLOR_WHITE,
        width: 160,
        height: 45,
        marginTop: 10,
        marginLeft: 20,
        fontSize: 16,
        lineHeight: 16,
        textAlign: 'left',
      },
    }),
  android: (wp, hp, theme) =>
    StyleSheet.create({
      balanceFooterContainer: {
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center',
      },
      balanceHead: {
        marginTop: 20,
      },
      contentHeadText: {
        fontFamily: theme.FONT_FAMILY,
        fontSize: 24,
        // fontWeight: 'normal',
        lineHeight: 26,
        textAlign: 'left',
        height: 26,
      },
      availableBalance: {
        width: 255,
        height: 180,
        marginTop: 28,
        paddingHorizontal: 20,
        paddingVertical: 10,
        borderRadius: 10,
        gap: 100,
        backgroundColor: theme.BLUE,
        alignItems: 'flex-start',
        justifyContent: 'center',
      },
      availableText: {
        fontFamily: theme.FONT_FAMILY_MEDIUM,
        color: theme.FONT_COLOR_WHITE,
        fontSize: 20,
        lineHeight: 20,
        textAlign: 'left',
      },
      availableText1: {
        fontFamily: theme.FONT_FAMILY_MEDIUM,
        color: theme.FONT_COLOR_WHITE,
        fontSize: 20,
        fontWeight: 'normal',
        lineHeight: 20,
        textAlign: 'left',
      },
      modalContainer: {
        // flex: 1,
        position: 'fixed',
        top: 0,
        alignItems: 'center',
        alignSelf: 'center',
        width: wp('100%'),
        maxWidth: 1080,
        height: hp('100%'),
        backgroundColor: '#0000001A',
        zIndex: 10,
        justifyContent: 'center',
      },
      modalContent: {
        // top: hp('2%'),
        width: wp('90%'),
        maxWidth: 392,
        height: 307,
        borderRadius: 40,
        borderColor: theme.BLACK,
        borderWidth: 1,
        backgroundColor: theme.WHITE,
        // justifyContent: 'center',
        // alignItems: 'center',
        zIndex: 11,
      },
      modalHead: {
        alignItems: 'flex-end',
        height: 52,
      },
      modalBody: {
        width: 267,
        height: 222,
        alignItems: 'center',
        // justifyContent: 'center',
        alignSelf: 'center',
        gap: 23,
      },
      modalCaption: {
        width: 180,
        height: 48,
        fontFamily: theme.FONT_FAMILY_MEDIUM,
        color: theme.FONT_COLOR_BLACK,
        fontSize: 40,
        fontWeight: 'normal',
        lineHeight: 48,
        textAlign: 'left',
        marginTop: -10,
      },
      inputContainer: {
        width: 251,
        height: 60,
      },
      sumLabel: {
        fontFamily: theme.FONT_FAMILY,
        color: theme.FONT_COLOR_BLUE,
        fontSize: 12,
        fontWeight: 'normal',
        lineHeight: 15,
        textAlign: 'left',
      },
      sumInput: {
        width: 250,
        height: 42,
        borderWidth: 1,
        borderRadius: 10,
        borderColor: theme.BLACK,
        padding: 10,
        fontFamily: theme.FONT_FAMILY,
        fontSize: 16,
        fontWeight: 'normal',
        lineHeight: 19,
        textAlign: 'left',
      },
      modalBtnGroup: {
        gap: 10,
      },
      modalBtn: {
        width: 114,
        height: 39,
        borderRadius: 10,
        backgroundColor: theme.BLACK,
        paddingVertical: 10,
        paddingHorizontal: 20,
        fontFamily: theme.FONT_FAMILY,
        fontSize: 16,
        fontWeight: 'normal',
        lineHeight: 19,
        textAlign: 'center',
        alignItems: 'center',
        justifyContent: 'center',
      },
      iconClose: {
        width: 20,
        height: 20,
        marginTop: 32,
        marginRight: 32,
      },
      balanceRowText: {
        width: '25%',
        textAlign: 'center',
        fontFamily: theme.FONT_FAMILY,
        color: theme.FONT_COLOR_WHITE,
        fontSize: 16,
        fontWeight: 'normal',
        lineHeight: 19,
      },
      btnGroup: {
        marginTop: 31,
        gap: 5,
        marginBottom: 32,
        // flex: 1,
      },
      balanceBtnWithdraw: {
        width: 180,
        height: 39,
        paddingHorizontal: 20,
        paddingVertical: 10,
        borderRadius: 10,
        backgroundColor: theme.BLACK,
        alignItems: 'center',
        justifyContent: 'center',
        fontFamily: theme.FONT_FAMILY,
        fontSize: 16,
        fontWeight: 'normal',
        lineHeight: 19,
        textAlign: 'left',
      },
      btnTextWhite: {
        fontFamily: theme.FONT_FAMILY,
        color: theme.FONT_COLOR_WHITE,
        fontSize: 16,
      },
      divider: {
        flex: 1,
      },
      cashbackStats: {
        marginTop: 32,
        gap: 12,
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'row',
        flexWrap: 'wrap',
      },
      cashbackStatBlack: {
        width: 184,
        height: 166,
        borderRadius: 20,
        backgroundColor: theme.BLACK,
      },
      cashbackStatBlue: {
        width: 184,
        height: 166,
        borderRadius: 20,
        backgroundColor: theme.BLUE,
      },
      cashbackStatTxt1: {
        width: 160,
        height: 35,
        marginTop: 20,
        marginLeft: 20,
        fontFamily: theme.FONT_FAMILY_MEDIUM,
        color: theme.FONT_COLOR_WHITE,
        fontSize: 16,
        lineHeight: 16,
        textAlign: 'left',
      },
      cashbackStatTxt2: {
        fontFamily: theme.FONT_FAMILY_MEDIUM,
        color: theme.FONT_COLOR_WHITE,
        height: 20,
        marginTop: 5,
        marginLeft: 20,
        fontSize: 18,
        lineHeight: 18,
        textAlign: 'left',
      },
      cashbackStatTxt3: {
        fontFamily: theme.FONT_FAMILY_MEDIUM,
        color: theme.FONT_COLOR_WHITE,
        width: 160,
        height: 35,
        marginTop: 10,
        marginLeft: 20,
        fontSize: 16,
        lineHeight: 16,
        textAlign: 'left',
      },
    }),
  ios: (wp, hp, theme) =>
    StyleSheet.create({
      balanceFooterContainer: {
        flex: 1,
      },
      balanceHead: {
        marginTop: 20,
      },
      contentHeadText: {
        fontFamily: theme.FONT_FAMILY,
        fontSize: 24,
        // fontWeight: 'normal',
        lineHeight: 26,
        textAlign: 'left',
        height: 26,
      },
      availableBalance: {
        // width: 354,
        height: 42,
        marginTop: 10,
        paddingHorizontal: 20,
        paddingVertical: 15,
        borderRadius: 10,
        gap: 10,
        backgroundColor: '#D9D9D9',
        alignItems: 'center',
        justifyContent: 'center',
      },
      availableText: {
        fontFamily: theme.FONT_FAMILY,
        fontSize: 12,
        // fontWeight: 'normal',
        lineHeight: 14,
        textAlign: 'center',
      },
      modalContainer: {
        // flex: 1,
        // position: 'fixed',
        // top: -150,
        alignItems: 'center',
        // justifyContent: 'center',
        width: 340,
        height: 350,
        // backgroundColor: '#0000001A',
        zIndex: 10,
        // display: 'none',
      },
      modalContent: {
        // top: -120,
        width: 330,
        height: 307,
        borderRadius: 40,
        backgroundColor: '#f1f1f1',
        // justifyContent: 'center',
        // alignItems: 'center',
        zIndex: 11,
      },
      modalHead: {
        alignItems: 'flex-end',
        height: 52,
      },
      modalBody: {
        width: 267,
        height: 222,
        alignItems: 'center',

        // justifyContent: 'center',
        alignSelf: 'center',
        gap: 23,
      },
      modalCaption: {
        width: 128,
        height: 48,
        fontFamily: theme.FONT_FAMILY,
        fontSize: 28,
        // fontWeight: 'normal',
        lineHeight: 48,
        textAlign: 'left',
        marginTop: -10,
      },
      inputContainer: {
        width: 251,
        height: 60,
      },
      sumLabel: {
        fontFamily: theme.FONT_FAMILY,
        fontSize: 12,
        // fontWeight: 'normal',
        lineHeight: 15,
        textAlign: 'left',
        color: '#707070',
      },
      sumInput: {
        width: 250,
        height: 42,
        borderWidth: 1,
        borderRadius: 10,
        borderColor: '#707070',
        padding: 10,
        fontFamily: theme.FONT_FAMILY,
        fontSize: 16,
        // fontWeight: 'normal',
        lineHeight: 19,
        textAlign: 'left',
      },
      modalBtnGroup: {
        gap: 23,
      },
      modalBtn: {
        width: 114,
        height: 39,
        borderRadius: 10,
        backgroundColor: '#D9D9D9',
        paddingVertical: 10,
        paddingHorizontal: 20,
        fontFamily: theme.FONT_FAMILY,
        fontSize: 16,
        fontWeight: 'normal',
        lineHeight: 19,
        textAlign: 'center',
        alignItems: 'center',
        justifyContent: 'center',
      },
      iconClose: {
        width: 20,
        height: 20,
        marginTop: 32,
        marginRight: 32,
      },
      btnGroup: {
        marginTop: 10,
        gap: 5,
        marginBottom: 80,
        flex: 1,
      },
      balanceBtnWithdraw: {
        // width: 184,
        height: 32,
        paddingHorizontal: 10,
        paddingVertical: 5,
        borderRadius: 10,
        backgroundColor: '#D9D9D9',
        color: '#000000',
        alignItems: 'center',
        justifyContent: 'center',
        fontFamily: theme.FONT_FAMILY,
        fontSize: 16,
        // fontWeight: 'normal',
        lineHeight: 19,
        textAlign: 'left',
      },
      btnProfile: {
        // width: 184,
        height: 32,
        paddingHorizontal: 10,
        paddingVertical: 5,
        border: 1,
        borderStyle: 'solid',
        borderRadius: 10,
        borderColor: '#000000',
        backgroundColor: '#FFF',
        color: '#000000',
        alignItems: 'center',
        justifyContent: 'center',
      },
      divider: {
        flex: 1,
      },
      cashbackStats: {
        marginTop: 32,
        gap: 12,
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'row',
        flexWrap: 'wrap',
      },
      cashbackStatBlack: {
        width: 184,
        height: 166,
        borderRadius: 20,
        backgroundColor: theme.BLACK,
      },
      cashbackStatBlue: {
        width: 184,
        height: 166,
        borderRadius: 20,
        backgroundColor: theme.BLUE,
      },
      cashbackStatTxt1: {
        width: 160,
        height: 45,
        marginTop: 20,
        marginLeft: 20,
        fontFamily: theme.FONT_FAMILY_MEDIUM,
        color: theme.FONT_COLOR_WHITE,
        fontSize: 16,
        lineHeight: 16,
        textAlign: 'left',
      },
      cashbackStatTxt2: {
        fontFamily: theme.FONT_FAMILY_MEDIUM,
        color: theme.FONT_COLOR_WHITE,
        height: 39,
        marginTop: 10,
        marginLeft: 20,
        fontSize: 18,
        lineHeight: 18,
        textAlign: 'left',
      },
      cashbackStatTxt3: {
        fontFamily: theme.FONT_FAMILY_MEDIUM,
        color: theme.FONT_COLOR_WHITE,
        width: 160,
        height: 45,
        marginTop: 10,
        marginLeft: 20,
        fontSize: 16,
        lineHeight: 16,
        textAlign: 'left',
      },
    }),
};

export default platformStyles[Platform.OS];
