import axios from 'axios';
// import Config from '../../config/config.js.sample/index.js';
import Config from '../../config/config.js';

const AxiosInstance = globalContext => {
  const instance = axios.create({
    baseURL: `${Config.API_URL}`, // Замените на свой базовый URL
    timeout: 10000, // Настройте таймаут, если необходимо
    headers: {
      'Content-Type': 'application/json',
      Authorization: globalContext.user.Authorization,
    },
  });

  // Глобальный обработчик успешных ответов
  instance.interceptors.response.use(
    response => {
      return response;
    },
    error => {
      console.log('AXIOS INSTANCE ERROR', error);
  
      if (error.response) {
        console.log('Axios error response', error.response);
  
        if (error.response.status === 401) {
          globalContext.logout();
          return Promise.reject(error.response.data.message || 'Unauthorized');
        } else if (error.response.status === 403) {
          return Promise.reject(error.response.data || 'Forbidden');
        }
  
        if (error.response.status < 500) {
          return Promise.reject(error.response.data.message || 'Client error');
        } else {
          // Возвращаем сообщение об ошибке, если оно есть, иначе стандартный текст
          return Promise.reject(
            error.response.data?.message || 'Internal server error'
          );
        }
      } else {
        // Ошибка без ответа сервера (например, сетевые проблемы)
        return Promise.reject(error.message || 'Network error');
      }
    }
  );
  

  return instance;
};

export default AxiosInstance;
