import React, { useContext, useState } from 'react';
import { Modal, View, Text, StyleSheet, Pressable } from 'react-native';
import { Stack } from 'native-base';
import { TextInputMask } from 'react-native-masked-text';
import { AuthContext } from '../../components/AuthProvider';
import {
	widthPercentageToDP as wp,
	heightPercentageToDP as hp,
} from 'react-native-responsive-screen';
import AxiosInstance from '../../components/common/axiosInstance';
import { default as profileStylesDesktop } from '../../styles/profile';
import { default as profileStylesMobile } from '../../styles/profile_mobile';

const AddCardModal = ({ modalVisible, setModalVisible, setCardUpdated, cardUpdated }) => {
	const {
		user,
		logout,
		screenWidth,
		theme,
		t
	} = useContext(AuthContext);
	let fontSizeBig =
		Math.ceil(screenWidth / 18) < 40 ? Math.ceil(screenWidth / 12) : 40;
	let fontSizeMd =
		Math.ceil(screenWidth / 20) < 24 ? Math.ceil(screenWidth / 14) : 24;
	let profileStyles =
		screenWidth < 1080
			? profileStylesMobile(wp, hp, fontSizeBig, fontSizeMd, theme)
			: profileStylesDesktop(wp, hp, fontSizeBig, fontSizeMd, theme);

	const [cardData, setCardData] = useState({
		card_number: '',
		expired_at: '',
		is_active: 1
	});

	const [isError, setIsError] = useState(null);

	const handleConfirm = async () => {
		// console.log('cardData', cardData);
		// setIsLoading(true);
		const api = AxiosInstance({user, logout});
		await api(`/api/v1/user_cards`, {
			method: 'POST',
			mode: 'cors',
			data: cardData,
		})
			.then(response => {
				if (response?.data?.status === 'success') {
					// console.log(response.data);
					setModalVisible(false);
					setCardUpdated(!cardUpdated);
				} else {
					setIsError(t('something_wrong'));
				}
			})
			.catch(error => {
				setIsError(t(`${error}`));
			})
			.finally(() => {
				// setIsLoading(false);
			});
	};

	const handleCancel = () => {
		// Действие при отмене
		setModalVisible(false);
	};

	return (
		<View style={styles.container}>
			<Modal
				visible={modalVisible}
				transparent={true}
				animationType="slide"
				onRequestClose={() => setModalVisible(false)} // Закрытие при нажатии кнопки "назад" на Android
			>
				<View style={styles.modalBackground}>
					<View style={styles.modalContainer}>
						<View style={{...profileStyles.formControl, marginTop: 10}}>
							<Text style={styles.modalText}>{t('add_card')}</Text>
							{isError && (
								<Text style={{
									fontSize: 14,
									fontFamily: theme.FONT_FAMILY,
									color: 'red'
								}}>{t(isError)}</Text>
							)}
							<Stack style={profileStyles.formStack}>
								<Text style={profileStyles.formLabel}>
									{t('card_number')}*
								</Text>
								<TextInputMask
									style={{...profileStyles.formInput, width: 250}}
									type={'custom'}
									options={{ mask: '9999 9999 9999 9999' }}
									placeholder="1234 5678 9876 54321"
									value={cardData.card_number}
									onChangeText={
										text =>	setCardData({
											...cardData,
											card_number: text.replaceAll(' ', '')
										})
									}
								/>
							</Stack>
							<Stack style={profileStyles.formStack}>
								<Text style={profileStyles.formLabel}>
									{t('expired_date')}*
								</Text>
								<TextInputMask
									style={{...profileStyles.formInput, width: 90}}
									type={'custom'}
									options={{ mask: '99/99' }}
									placeholder={t('MM/YY')}
									value={cardData.expired_at}
									onChangeText={
										text =>	setCardData({
											...cardData,
											expired_at: text
										})
									}
								/>
							</Stack>
						</View>
						<View style={styles.buttonContainer}>
							<Pressable
								style={profileStyles.formBtn}
								onPress={handleCancel}
							>
								<Text style={profileStyles.frmButtonText}>{t('cancel')}</Text>
							</Pressable>
							<Pressable
								style={{ ...profileStyles.formBtn, backgroundColor: 'red' }}
								onPress={handleConfirm}
							>
								<Text style={profileStyles.frmButtonText}>{t('add')}</Text>
							</Pressable>
						</View>
					</View>
				</View>
			</Modal>
		</View>
	);
};

const styles = StyleSheet.create({
	container: {
		// flex: 1,
		justifyContent: 'center',
		alignItems: 'center',
	},
	modalBackground: {
		flex: 1,
		justifyContent: 'center',
		alignItems: 'center',
		backgroundColor: 'rgba(0, 0, 0, 0.5)',
	},
	modalContainer: {
		width: 300,
		padding: 20,
		backgroundColor: 'white',
		borderRadius: 10,
		alignItems: 'center',
	},
	modalText: {
		marginBottom: 20,
		fontSize: 18,
	},
	buttonContainer: {
		marginTop: 30,
		flexDirection: 'row',
		justifyContent: 'space-between',
		width: '100%',
	},
});

export default AddCardModal;