import React, {useContext, useState, useEffect, useCallback} from 'react';
import {View, Pressable, Platform, TextInput} from 'react-native';
import {Picker} from '@react-native-picker/picker';
import {Text, HStack, Radio} from 'native-base';
import AxiosInstance from '../../components/common/axiosInstance';
import {
  widthPercentageToDP as wp,
  heightPercentageToDP as hp,
} from 'react-native-responsive-screen';
import {SegmentedButtons, DataTable} from 'react-native-paper';

import Header from '../../components/common/AdminHeader';
import Footer from '../../components/common/Footer';
import Loader from '../../components/common/Loader';
import ErrorModal from '../../components/common/ErrorModal';
import InfoModal from '../../components/common/InfoModal';
import {AuthContext} from '../../components/AuthProvider';

import commonStylesF from '../../styles/common';
import listStylesF from '../../styles/list';
import adminStyles from '../../styles/admin';
import {t} from 'i18next';

const WithdrawsHeader = props => {
  const {theme, t} = useContext(AuthContext);
  const listStyles = listStylesF(theme);

  const dateSort = (currSort, direction) => {
    if (currSort !== 'created_at') {
      props.setSortField('created_at');
    }
    direction === 'desc'
      ? props.setSortDirection('asc')
      : props.setSortDirection('desc');
  };

  const TableTitleDate = props => {
    return (
      <DataTable.Title
        sortDirection={
          props.sortDirection === 'desc' ? 'descending' : 'ascending'
        }>
        <Pressable
          onPress={() => {
            dateSort(props.sortField, props.sortDirection);
          }}>
          <HStack style={Platform.OS === 'ios' ? {alignItems: 'center'} : {}}>
            <Text style={{...listStyles.itemsHeaderText, marginLeft: 10}}>
              {t('date')}
            </Text>
          </HStack>
        </Pressable>
      </DataTable.Title>
    );
  };
  return (
    <DataTable.Header style={listStyles.itemsHeader}>
      <TableTitleDate
        sortField={props.sortField}
        sortDirection={props.sortDirection}
      />
      <DataTable.Title style={listStyles.itemCell}>
        <Text style={listStyles.itemsHeaderText}>{t('admin_user')}</Text>
      </DataTable.Title>
      <DataTable.Title style={listStyles.itemCell}>
        <Text style={listStyles.itemsHeaderText}>{t('sum')}</Text>
      </DataTable.Title>
      <DataTable.Title style={listStyles.itemCell}>
        <Text style={listStyles.itemsHeaderText}>{t('status')}</Text>
      </DataTable.Title>
      <DataTable.Title style={listStyles.itemCell}>
        <Text style={listStyles.itemsHeaderText}>{t('updated_at')}</Text>
      </DataTable.Title>
    </DataTable.Header>
  );
};

const WithdrawItem = ({item, showWithdraw}) => {
  const {theme} = useContext(AuthContext);
  const listStyles = listStylesF(theme);
  let status_str = '';
  let rowStyle = '';
  switch (item.status) {
    case 'new':
      status_str = t('new_request');
      rowStyle = adminStyles.new;
      break;
    case 'completed':
      status_str = t('completed_request');
      rowStyle = adminStyles.completed;
      break;
  }

  return (
    <DataTable.Row
      key={item.id}
      onPress={() => {
        showWithdraw(item);
      }}
      style={[listStyles.itemRow, rowStyle]}>
      <DataTable.Cell style={listStyles.itemCol}>
        <Text style={listStyles.itemCol}>{item.created_at}</Text>
      </DataTable.Cell>
      <DataTable.Cell style={listStyles.itemCol}>
        <Text style={listStyles.itemCol}>{item.name}</Text>
      </DataTable.Cell>
      <DataTable.Cell style={listStyles.itemCol}>
        <Text style={listStyles.itemCol}>
          {item.amount} {item.currency}
        </Text>
      </DataTable.Cell>
      <DataTable.Cell style={listStyles.itemCol}>
        <Text style={listStyles.itemCol}>{status_str}</Text>
      </DataTable.Cell>
      <DataTable.Cell style={listStyles.itemCol}>
        <Text style={listStyles.itemCol}>{item.updated_at}</Text>
      </DataTable.Cell>
    </DataTable.Row>
  );
};

const AdminWithdrawScreen = ({navigation}) => {
  const {user, logout, theme} = useContext(AuthContext);
  const commonStyles = commonStylesF(wp, hp, theme);
  const listStyles = listStylesF(theme);

  const [withdraws, setWithdraws] = useState({});
  const [activeWithdraw, setActiveWithdraw] = useState(null);
  const [loading, setLoading] = useState(true);
  const [isError, setIsError] = useState(null);
  const [info, setInfo] = useState(null);
  const [pagination, setPagination] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const [sortField, setSortField] = useState('created_at');
  const [sortDirection, setSortDirection] = useState('desc');
  const [status, setStatus] = useState('new');
  const [userCards, setUserCards] = useState({});
  const [from, setFrom] = useState(0);
  const [to, setTo] = useState(0);

  const getUserBalance = async user_id => {
    setLoading(true);
    try {
      const api = AxiosInstance({user, logout});
      const response = await api(`/api/v1/admin/user_balance/${user_id}`, {
        method: 'GET',
        mode: 'cors',
      });
      return response.data.message;
    } catch (error) {
      setIsError(error);
      return false;
    } finally {
      setLoading(false);
    }
  };

  const getUserCards = async user_id => {
    setLoading(true);
    try {
      const api = AxiosInstance({user, logout});
      const response = await api(`/api/v1/admin/user_cards/${user_id}`, {
        method: 'GET',
        mode: 'cors',
      });
      return response.data.message;
    } catch (error) {
      setIsError(error);
      return false;
    } finally {
      setLoading(false);
    }
  };

  const showWithdraw = item => {
    getUserBalance(item.user_id)
      .then(balance => {
        item.curr_balance = balance.curr_balance;
      })
      .catch(error => {
        setIsError(error);
      });

    getUserCards(item.user_id)
      .then(cards => {
        item.cards = cards;
        setActiveWithdraw(item);
      })
      .catch(error => {
        setIsError(error);
      });
  };

  const updateWithdraw = async () => {
    setLoading(true);
    try {
      const api = AxiosInstance({user, logout});
      const response = await api(`/api/v1/withdraw/${activeWithdraw.id}`, {
        method: 'PUT',
        mode: 'cors',
        data: {
          user_id: activeWithdraw.user_id,
          status: activeWithdraw.status,
          notes: activeWithdraw.notes,
          card_id: activeWithdraw.card_id,
        },
      });
      if (response.data.code === 201) {
        setActiveWithdraw(null);
        loadWithdraws();
        setInfo('Заявка обновлена');
      } else {
        setIsError(t('server_error'));
        console.log(response.data);
      }
    } catch (error) {
      setIsError(t('server_error'));
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const loadWithdraws = useCallback(async () => {
    setLoading(true);
    const api = AxiosInstance({user, logout});
    let url = `/api/v1/admin/withdraw?page=${currentPage}&order_field=${sortField}&order=${sortDirection}`;
    if (status !== null) {
      url += `&status=${status}`;
    }
    await api(url, {
      method: 'GET',
      mode: 'cors',
    })
      .then(response => {
        let _from = 0;
        let _to = 0;
        setWithdraws(response.data.message.data);
        setPagination(response.data.message.pagination);
        _from =
          response.data.message.pagination.totalRows > 0
            ? (currentPage - 1) * response.data.message.pagination.perPage + 1
            : 0;
        _to =
          response.data.message.pagination.totalRows >
          currentPage * response.data.message.pagination.perPage
            ? currentPage * response.data.message.pagination.perPage
            : response.data.message.pagination.totalRows;
        setFrom(_from);
        setTo(_to);
      })
      .catch(error => {
        setIsError(error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [user, currentPage, status, sortField, sortDirection, logout]);

  useEffect(() => {
    if (navigation.isFocused()) {
      loadWithdraws();
    }
  }, [loadWithdraws, navigation]);
  console.log(activeWithdraw?.cards)
  if (loading) {
    return <Loader />;
  } else if (!activeWithdraw) {
    return (
      <View style={commonStyles.container}>
        <Header navigation={navigation} />
        <View style={commonStyles.contentContainer}>
          <View style={commonStyles.contentHead}>
            <Text style={commonStyles.contentHeadText}>
              {t('admin_withdraw_requests')}
            </Text>
          </View>

          <SegmentedButtons
            style={{
              width: wp('90%'),
              maxWidth: 600,
              marginTop: 20,
            }}
            theme={{
              colors: {
                secondaryContainer: theme.BLACK,
                onSecondaryContainer: theme.FONT_COLOR_WHITE,
              },
            }}
            value={status}
            onValueChange={value => {
              setStatus(value);
            }}
            buttons={[
              {
                value: 'new',
                label: t('new_requests'),
              },
              {
                value: 'completed',
                label: t('completed_requests'),
              },
              {
                value: null,
                label: t('all'),
              },
            ]}
          />

          <DataTable style={listStyles.dataTable}>
            <WithdrawsHeader
              sortField={sortField}
              sortDirection={sortDirection}
            />
            {withdraws.map(item => (
              <WithdrawItem item={item} showWithdraw={showWithdraw} />
            ))}
            <DataTable.Pagination
              page={currentPage - 1}
              numberOfPages={Math.ceil(pagination.totalPages)}
              onPageChange={page => setCurrentPage(page + 1)}
              label={`${from}-${to} of ${pagination.totalRows}`}
              numberOfItemsPerPage={pagination.perPage}
              showFastPaginationControls
            />
          </DataTable>

          <HStack style={listStyles.itemsFooterContainer}>
            <HStack style={commonStyles.btnGroup}>
              <Pressable
                style={commonStyles.btnCommonBig}
                onPress={() => navigation.navigate('ActiveUsers')}>
                <Text style={commonStyles.btnCommonTxtBig}>
                  {t('admin_users')}
                </Text>
              </Pressable>
            </HStack>
          </HStack>
        </View>
        {/* <Footer /> */}
        {info && <InfoModal info={info} setInfo={setInfo} />}
        {isError && <ErrorModal error={isError} setIsError={setIsError} />}
      </View>
    );
  } else {
    return (
      <View style={commonStyles.container}>
        <Header navigation={navigation} />
        <View style={commonStyles.contentContainer}>
          <View style={commonStyles.contentHead}>
            <Text style={commonStyles.contentHeadText}>
              {t('withdraw_request')}
            </Text>
          </View>
          <HStack style={adminStyles.row}>
            <Text style={adminStyles.key}>{t('created_at')}</Text>
            <Text style={adminStyles.value}>{activeWithdraw.created_at}</Text>
          </HStack>
          <HStack style={adminStyles.row}>
            <Text style={adminStyles.key}>{t('admin_user')}</Text>
            <Text style={adminStyles.value}>{activeWithdraw.name}</Text>
          </HStack>
          <HStack style={adminStyles.row}>
            <Text style={adminStyles.key}>{t('phone')}</Text>
            <Text style={adminStyles.value}>{activeWithdraw.phone}</Text>
          </HStack>
          <HStack style={adminStyles.row}>
            <Text style={adminStyles.key}>{t('card_number')}</Text>
            <Text style={adminStyles.value}>{activeWithdraw?.cards?.find(item => item.id === activeWithdraw.card_id)?.card_number ?? '?'}</Text>
          </HStack>
          <HStack style={adminStyles.row}>
            <Text style={adminStyles.key}>{t('admin_available')}</Text>
            <Text style={adminStyles.value}>
              {activeWithdraw?.curr_balance ?? 0} {activeWithdraw.currency}
            </Text>
          </HStack>
          <HStack style={adminStyles.row}>
            <Text style={adminStyles.key}>{t('sum')}</Text>
            <Text style={adminStyles.value}>
              {activeWithdraw.amount} {activeWithdraw.currency}
            </Text>
          </HStack>
          <HStack style={adminStyles.row}>
            <Text style={adminStyles.key}>{t('status')}</Text>
            <Picker
              // style={profileStyles.formSelect}
              selectedValue={activeWithdraw.status}
              onValueChange={value =>
                setActiveWithdraw({...activeWithdraw, status: value})
              }
              enabled={activeWithdraw.status === 'completed' ? false : true}>
              <Picker.Item key={0} label={t('new')} value={'new'} />
              <Picker.Item key={2} label={t('completed')} value={'completed'} />
            </Picker>
            {/* <Text style={adminStyles.value}>{activeWithdraw.status}</Text> */}
          </HStack>
          <HStack style={adminStyles.row}>
            <Text style={adminStyles.key}>{t('admin_note')}</Text>
            <TextInput
              style={adminStyles.textArea}
              multiline={true}
              numberOfLines={4}
              value={activeWithdraw.notes}
              onChangeText={text =>
                setActiveWithdraw({...activeWithdraw, notes: text})
              }
            />
          </HStack>
          <HStack style={adminStyles.row}>
            <Text style={adminStyles.key}>{t('last_change')}</Text>
            <Text style={adminStyles.value}>{activeWithdraw.updated_at}</Text>
          </HStack>
          <HStack style={commonStyles.btnGroup}>
            <Pressable
              style={commonStyles.btnCommonBig}
              onPress={() => setActiveWithdraw(null)}>
              <Text style={commonStyles.btnCommonTxtBig}>{t('admin_withdraw_requests')}</Text>
            </Pressable>
            <Pressable
              style={commonStyles.btnCommonBig}
              onPress={() => updateWithdraw()}>
              <Text style={commonStyles.btnCommonTxtBig}>{t('save')}</Text>
            </Pressable>
          </HStack>
        </View>
        {/* <Footer /> */}
        {info && <InfoModal info={info} setInfo={setInfo} />}
        {isError && <ErrorModal error={isError} setIsError={setIsError} />}
      </View>
    );
  }
};

export default AdminWithdrawScreen;
