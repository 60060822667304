import React, {useState, useContext, useEffect, useCallback} from 'react';
import {
  Text,
  View,
  ScrollView,
  Pressable,
  ActivityIndicator,
  Image,
  Platform,
  StyleSheet,
} from 'react-native';
import {DataTable} from 'react-native-paper';
import {
  widthPercentageToDP as wp,
  heightPercentageToDP as hp,
} from 'react-native-responsive-screen';
import {AuthContext} from '../../components/AuthProvider';
import AxiosInstance from '../../components/common/axiosInstance';

import Header from '../../components/common/Header';
import Footer from '../../components/common/Footer';
import Loader from '../../components/common/Loader';
import ErrorModal from '../../components/common/ErrorModal';
import { OrderHeaderTable, OrderHeaderCard, OrderTable, OrderCard } from './OrderItem';

import ArrowLeft from '../../assets/img/arrow_left_white.svg';

import Config from '../../config/config';

import {default as commonStylesF} from '../../styles/common';
import {default as commonStylesMobileF} from '../../styles/common_mobile';
import {default as listStylesF} from '../../styles/list';

const RefOrders = ({navigation, route}) => {
  const {user, logout, t, screenWidth, theme, i18n, screenParams, setScreenParams, addHistory} = useContext(AuthContext);

  const [refOrders, setRefOrders] = useState([]);
  const [pagination, setPagination] = useState({});
  const [isLoading, setIsLoading] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [sortField, setSortField] = useState('created_at');
  const [sortDirection, setSortDirection] = useState('ASC');
  const [isError, setIsError] = useState(false);
  const [from, setFrom] = useState(0);
  const [to, setTo] = useState(0);

  const OrderHeader = screenWidth < 700 ? OrderHeaderCard : OrderHeaderTable;
  const Order = screenWidth < 700 ? OrderCard : OrderTable;

  // const [refId, setRefId] = useState(null);
  let refId  = false;
  let refName = '';
  if (screenParams.id) {
    console.log('SCREEN PARAMS', screenParams.id);
    refId = screenParams.id;
  } else if (route.params) {
    console.log('ROUTE PARAMS', route.params);
    refId = route.params.id;
  }

  if (screenParams.name) {
    refName = screenParams.name;
  }

  const commonStyles =
    screenWidth < 1080
      ? commonStylesMobileF(wp, hp, theme)
      : commonStylesF(wp, hp, theme);

  const listStyles = listStylesF(theme);

  useEffect(() => {
    const getRefOrders = async () => {
      if (!refId) {
        setIsError(t('empty_ref_id'));
        return false;
      }
      setIsLoading(true);
      const api = AxiosInstance({user, logout});
      await api(`${Config.API_URL}/api/v1/trios/orders/${refId}?page=${currentPage}&order_field=${sortField}&order=${sortDirection}`, {
        method: 'GET',
        mode: 'cors',
      }).then(response => {
        let _from = 0;
        let _to = 0;
        if (response.status === 200) {
            setRefOrders(response.data.message.data);
            setPagination(response.data.message.pagination);
            _from =
              response.data.message.pagination.totalRows > 0
                ? (currentPage - 1) *
                    response.data.message.pagination.perPage +
                  1
                : 0;
            _to =
              response.data.message.pagination.totalRows >
              currentPage * response.data.message.pagination.perPage
                ? currentPage *
                  response.data.message.pagination.perPage
                : response.data.message.pagination.totalRows;
            setFrom(_from);
            setTo(_to);
        }
      }).catch(error => {
        setIsError(t('server_error'));
        console.log(error);
      }).finally(() =>{
        setIsLoading(false);
      });
    }

    getRefOrders();

  }, [user, logout, screenParams, currentPage, sortField, sortDirection, refId, t]);

  useEffect(() => {
    if (Platform.OS !== 'web') {
      addHistory(route.name);
    }
  }, [addHistory, route]);

  if (isLoading) {
    return <Loader />
  }
  return (
    <ScrollView contentContainerStyle={commonStyles.container}>
      <Header navigation={navigation} route={route} />
      <View style={commonStyles.contentContainer}>
        <View style={commonStyles.headerContainer}>
          <Pressable
            style={commonStyles.headBackButton}
            onPress={() => navigation.navigate('Moneybox')}>
            {Platform.OS === 'web' ? (
              <Image source={ArrowLeft} style={commonStyles.arrowLeft} />
            ) : (
              <ArrowLeft style={commonStyles.arrowLeft} />
            )}
          </Pressable>
          <View style={commonStyles.contentHead}>
            <Text style={commonStyles.contentHeadText}>{t('moneybox')}</Text>
          </View>
        </View>
        <View style={styles.refInfo}>
            <View>
              <Text style={styles.refName}>
                {refName}
              </Text>
            </View>
          </View>
        <DataTable style={listStyles.dataTable}>
          <OrderHeader
              sortField={sortField}
              sortDirection={sortDirection}
              setSortField={setSortField}
              setSortDirection={setSortDirection}
          />
          {refOrders?.map(
            item => (<Order item={item} key={item.id} />)
          )}
          <DataTable.Pagination
            page={currentPage - 1}
            numberOfPages={Math.ceil(pagination.totalPages)}
            onPageChange={page => setCurrentPage(page + 1)}
            label={`${from}-${to} of ${pagination.totalRows}`}
            numberOfItemsPerPage={pagination.perPage}
            showFastPaginationControls
          />
        </DataTable>

      </View>
      <Footer navigation={navigation}/>
      {isError && <ErrorModal error={isError} setIsError={setIsError} />}
      {isLoading === true && (
          <ActivityIndicator
            style={styles.activityIndicator}
            color={theme.BLUE}
            size="large"
          />
        )}

    </ScrollView>
  );
}

const styles = StyleSheet.create({
  activityIndicator: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'transparent', // Прозрачный фон
  },
  refInfo: {
    marginTop: 47,
    width: '90%',
    height: 40,
    gap: 11,
  },
  refName: {
    fontFamily: 'Inter',
    fontSize: 40,
    fontWeight: 'normal',
    lineHeight: 48,
    textAlign: 'center',
    width: '100%',
  }
});

export default RefOrders;
