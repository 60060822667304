import React, { useState, useContext, useEffect } from 'react';
import { useIsFocused } from '@react-navigation/native';
import {
  Image,
  Pressable,
  Text,
  TextInput,
  Platform,
  ScrollView,
  ImageBackground,
  KeyboardAvoidingView,
  Dimensions,
} from 'react-native';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';
import { Picker } from '@react-native-picker/picker';
import AsyncStorage from '@react-native-async-storage/async-storage';
import {
  Box,
  HStack,
  VStack,
  View,
  Stack,
  FormControl,
  Checkbox,
} from 'native-base';
import { TextInputMask } from 'react-native-masked-text';
import axios from 'axios';

import AxiosInstance from '../../components/common/axiosInstance';

import Header from '../../components/common/Header';
import Footer from '../../components/common/Footer';
import Loader from '../../components/common/Loader';
import { AuthContext } from '../../components/AuthProvider';
import Config from '../../config/config';
import {
  formInitials,
  formatPhoneNumber,
} from '../../components/common/commonFunctions';
import {
  widthPercentageToDP as wp,
  heightPercentageToDP as hp,
} from 'react-native-responsive-screen';

import ArrowLeft from '../../assets/img/arrow_left_white.svg';
import { formatCardNumber } from '../../components/common/commonFunctions';
import ErrorModal from '../../components/common/ErrorModal';
import InfoModal from '../../components/common/InfoModal';
import ImagePicker from '../../components/common/imageUploader';
import ConfirmationModal from '../../components/common/ConfirmationModal';
import AddCardModal from './AddCardModal';

import { default as commonStylesDesktop } from '../../styles/common';
import { default as commonStylesMobile } from '../../styles/common_mobile';
import { default as profileStylesDesktop } from '../../styles/profile';
import { default as profileStylesMobile } from '../../styles/profile_mobile';

const ProfileEditing = ({ navigation, route }) => {
  const {
    user,
    logout,
    t,
    screenWidth,
    theme,
    screenParams,
    setScreenParams,
    addHistory,
  } = useContext(AuthContext);
  // console.log('profile edit route.params', route.params);
  let fontSizeBig =
    Math.ceil(screenWidth / 18) < 40 ? Math.ceil(screenWidth / 12) : 40;
  let fontSizeMd =
    Math.ceil(screenWidth / 20) < 24 ? Math.ceil(screenWidth / 14) : 24;
  let commonStyles =
    screenWidth < 1080
      ? commonStylesMobile(wp, hp, theme)
      : commonStylesDesktop(wp, hp, theme);
  let profileStyles =
    screenWidth < 1080
      ? profileStylesMobile(wp, hp, fontSizeBig, fontSizeMd, theme)
      : profileStylesDesktop(wp, hp, fontSizeBig, fontSizeMd, theme);

  // const _userData = route.params.userData ? route.params.userData : user;

  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(null);
  const [info, setInfo] = useState('');
  const [confirmVisible, setConfirmVisible] = useState(false);
  const [confirmed, setConfirmed] = useState(false);
  const [updatedUserData, setUpdatedUserData] = useState(screenParams);
  const [editImage, setEditImage] = useState(false);
  const [userCards, setUserCards] = useState([]);
  const [showAddCard, setShowAddCard] = useState(false);
  const [cardUpdated, setCardUpdated] = useState(false);
  const isFocused = useIsFocused();

  const handleDelete = async () => {
    setConfirmVisible(true);
  }

  const handleUpdate = async () => {
    let dataToSend = {
      name: updatedUserData.name,
      phone: updatedUserData.phone,
      card_id: updatedUserData.card_number,
      email: updatedUserData.email,
      send_notifications: updatedUserData.send_notifications,
      currency: updatedUserData.currency,
      photo: updatedUserData.photo,
      new_password: updatedUserData.new_password,
      repeated_password: updatedUserData.repeated_password
    };

    if (!dataToSend.name) {
      setIsError(t('type_name'));
      return false;
    }

    if (!dataToSend.phone) {
      setIsError(t('type_phone'));
      return false;
    }

    if (dataToSend?.new_password) {

      if (dataToSend.new_password.length < 6) {
        setIsError(t('short_password'));
        return false;
      }

      if (!dataToSend?.repeated_password) {
        setIsError(t('repeat_password'));
        return false;
      } else if (dataToSend.new_password !== dataToSend.repeated_password) {
        setIsError(t('password_mismatch'));
        return false;
      }

      dataToSend.password = dataToSend.new_password;
    }

    const api = AxiosInstance({ user, logout });
    setIsLoading(true);
    await api(`/api/v1/users/${user.id}`, {
      method: 'PUT',
      mode: 'cors',
      data: dataToSend,
    })
      .then(response => {
        // console.log('Profile edit response', response);
        if (response.data && response.data.status === 'success') {
          setScreenParams({});
          navigation.navigate('Profile');
        } else {
          setIsError(t('something_wrong'));
        }
      })
      .catch(error => {
        setIsError(t(`${error}`));
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const uploadPhoto = async file => {
    if (file !== undefined && file.size > 1024 * 500) {
      console.log(file);
      setIsError(t('file_size_error'));
      return false;
    }
    // Ініціалізація та відправка FormData
    const formData = new FormData();
    formData.append('file', file);

    await axios
      .post(
        `${Config.API_URL}/api/v1/users/upload_photo/${user.id}`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: user.Authorization,
          },
        }
      )
      .then(response => {
        console.log('upload foto response', response);
        if (response.status === 200) {
          setUpdatedUserData({
            ...updatedUserData,
            photo: response.data.message.encodedImage,
          });
        }
      })
      .catch(error => {
        console.error('Error uploading image:', error);
        setIsError(t('photo_upload_error'));
      });
  };

  const pickFile = async () => {
    if (Platform.OS === 'ios') {
      const options = {
        mediaType: 'photo',
        includeBase64: true,
      };

      ImagePicker(options, response => {
        if (response.didCancel) {
          console.log('User cancelled image picker');
        } else if (response.error) {
          console.log('ImagePicker Error: ', response.error);
        } else {
          const file = {
            uri: response.assets[0].uri,
            type: response.assets[0].type,
            name: response.assets[0].fileName || `photo_${Date.now()}.jpg`,
          };
          uploadPhoto(file);
        }
      });
    } else {
      console.log('ios ImagePicker', ImagePicker);
      ImagePicker.showFilePicker(null, response => {
        if (!response.didCancel) {
          uploadPhoto({
            name: response.fileName,
            size: response.size,
            type: response.type,
            uri: response.uri,
          });
        }
      });
    }
  };

  const AddCard = () => {
    setShowAddCard(true);
  }

  useEffect(() => {
    const sendDeleteConfirmation = async () => {
      const api = AxiosInstance({ user, logout });
      setIsLoading(true);
      await api(`/api/v1/users/confirm_delete`, {
        method: 'POST',
        mode: 'cors',
      })
        .then(response => {
          if (response.data && response.data.status === 'success') {
            console.log('delete confirmation response', response.data);
            navigation.navigate('ProfileDeleteCodeVerification', { userData: updatedUserData });
          } else {
            setIsError(t('something_wrong'));
          }
        })
        .catch(error => {
          setIsError(t(`${error}`));
        })
        .finally(() => {
          setIsLoading(false);
          setConfirmed(false);
        });
    }

    if (confirmed) {
      sendDeleteConfirmation();
    } else {
      console.log('deletion canceled');
    }
  }, [confirmed, logout, t, user, updatedUserData, navigation]);

  useEffect(() => {
    const getUserCards = async () => {
      const api = AxiosInstance({ user, logout });
      await api(`/api/v1/user_cards`, {
        method: 'GET',
        mode: 'cors',
      })
        .then(response => {
          if (response.data && response.data.status === 'success') {
            console.log('user cards', response.data.message);
            setUserCards(response.data.message);
          } else {
            setIsError(t('something_wrong'));
          }
        })
        .catch(error => {
          setIsError(t(`${error}`));
        })
    }

    getUserCards();
  }, [logout, t, user, cardUpdated]);

  useEffect(() => {
    if (Platform.OS !== 'web') {
      addHistory(route.name);
    }
  }, [addHistory, route]);

  if (isLoading) {
    return <Loader />;
  } else {
    return (
      <ScrollView contentContainerStyle={profileStyles.scrollableContainer}>
        <Header navigation={navigation} route={route} />
        <View style={commonStyles.headerContainer}>
          <Pressable
            style={commonStyles.headBackButton}
            onPress={() => navigation.navigate('Profile')}>
            {Platform.OS === 'web' ? (
              <Image source={ArrowLeft} style={commonStyles.arrowLeft} />
            ) : (
              <ArrowLeft style={commonStyles.arrowLeft} />
            )}
          </Pressable>
          <View style={{ ...commonStyles.contentHead, height: 60 }}>
            <Text style={commonStyles.contentHeadText}>
              {t('editing_profile')}
            </Text>
          </View>
        </View>
        <View style={profileStyles.profileContainer}>
          <HStack style={profileStyles.editShortDesc}>
            <View style={profileStyles.editAvatar}>
              {/* <View> */}
              <Pressable
                onPress={() =>
                  Platform.OS === 'web' ? setEditImage(!editImage) : pickFile()
                }
                style={{ flexDirection: 'row', alignItems: 'center' }}>
                {updatedUserData.photo ? (
                  <Image
                    style={{ ...profileStyles.editAvatar, marginLeft: 0 }}
                    source={{
                      uri: `data:image/jpeg;base64,${updatedUserData.photo}`,
                    }}
                  />
                ) : (
                  <ImageBackground
                    source={require('../../assets/img/avatar100x100.png')}
                    style={{ ...profileStyles.image }}>
                    <Text style={profileStyles.initials}>
                      {formInitials(updatedUserData.name)}
                    </Text>
                  </ImageBackground>
                )}

                {Platform.OS === 'web' && (
                  <View
                    style={{
                      display: editImage ? 'inline' : 'none',
                      ...profileStyles.selectImgContainer,
                    }}>
                    <label style={profileStyles.selectImageInput}>
                      {t('select')}
                      <input
                        type="file"
                        onChange={e => uploadPhoto(e.target.files[0])}
                        accept="image/*"
                        // style={profileStyles.selectImageInput}
                        style={{ display: 'none' }}
                        name="avatar"
                      />
                    </label>
                  </View>
                )}
              </Pressable>
            </View>
            <VStack style={profileStyles.profileTextContainer}>
              <Text
                style={{
                  ...profileStyles.nameTextEditing,
                  marginLeft: 0,
                  paddingVertical: 5,
                }}>
                {updatedUserData.name}
              </Text>
              <Text style={profileStyles.phoneText}>
                {formatPhoneNumber(updatedUserData.phone)}
              </Text>
            </VStack>
          </HStack>
          <View style={profileStyles.formControl}>
            {Platform.OS !== 'ios' ? (
              <KeyboardAvoidingView>
                {/* <ScrollView> */}
                <View>
                  <FormControl isRequired>
                    <Stack style={profileStyles.formStack}>
                      <Text style={profileStyles.formLabel}>{t('name')}*</Text>
                      <TextInput
                        style={profileStyles.formInput}
                        placeholder="Имя"
                        value={updatedUserData.name}
                        onChangeText={text =>
                          setUpdatedUserData({
                            ...updatedUserData,
                            name: text,
                          })
                        }
                      />
                    </Stack>
                    <Stack style={profileStyles.formStack}>
                      <Text style={profileStyles.formLabel}>
                        {t('phone_number')}*
                      </Text>
                      <TextInputMask
                        style={{
                          ...profileStyles.formInput,
                          backgroundColor: '#ccc',
                        }}
                        type={'custom'}
                        editable={false}
                        options={{ mask: '+380(99) 999-99-99' }}
                        placeholder="+380(99)123-45-67"
                        value={updatedUserData.phone}
                      />
                    </Stack>
                    <Stack style={profileStyles.formStack}>
                      <Text style={profileStyles.formLabel}>
                        {t('email')}*
                      </Text>
                      <TextInput
                        style={{
                          ...profileStyles.formInput,
                          backgroundColor: '#ccc',
                        }}
                        placeholder="email"
                        value={updatedUserData.email}
                        editable={false}
                      />
                    </Stack>
                    <Stack style={profileStyles.formStack}>
                      <Text style={profileStyles.formLabel}>
                        {t('card_number')}*
                      </Text>
                    <Picker
                      style={profileStyles.formInput}
                      selectedValue={updatedUserData.card_number ?? ''}
                      onValueChange={(itemValue) => setUpdatedUserData({
                          ...updatedUserData,
                          card_number: itemValue,
                        })}
                    >
                      <Picker.Item key={0} label={t('select')} value="" />
                      {userCards.length > 0 && userCards.map((card) => (
                        <Picker.Item key={card.id} label={formatCardNumber(card.card_number)} value={card.id} />
                      ))}
                    </Picker>
                    </Stack>
                    <Stack style={{...profileStyles.formStack, height: 30}}>
                      <Pressable
                        style={profileStyles.addCardButton}
                        onPress={() => AddCard()}
                      >
                        <Text style={profileStyles.addCardText}>{t('add_card')}</Text>
                      </Pressable>
                    </Stack>
                    <Stack style={profileStyles.formStack}>
                      <Text style={profileStyles.formLabel}>{t('new_password')}</Text>
                      <TextInput
                        style={profileStyles.formInput}
                        placeholder={t('new_password')}
                        value={updatedUserData?.new_password ?? ""}
                        onChangeText={text =>
                          setUpdatedUserData({
                            ...updatedUserData,
                            new_password: text,
                          })
                        }
                      />
                    </Stack>
                    <Stack style={profileStyles.formStack}>
                      <Text style={profileStyles.formLabel}>{t('repeat_password')}</Text>
                      <TextInput
                        style={profileStyles.formInput}
                        placeholder={t('repeat_password')}
                        value={updatedUserData?.repeated_password  ?? ""}
                        onChangeText={text =>
                          setUpdatedUserData({
                            ...updatedUserData,
                            repeated_password: text,
                          })
                        }
                      />
                    </Stack>
                    <Stack
                      style={[
                        profileStyles.formStack,
                        Platform.OS === 'ios' ? { marginTop: 20 } : {},
                      ]}>
                      <Checkbox
                        style={profileStyles.frmCheckbox}
                        value={
                          updatedUserData.send_notifications ? true : false
                        }
                        isChecked={
                          updatedUserData.send_notifications ? true : false
                        }
                        onChange={value =>
                          setUpdatedUserData({
                            ...updatedUserData,
                            send_notifications: value ? 1 : 0,
                          })
                        }>
                        <Text style={profileStyles.checkBoxTxt}>
                          {t('notify_trios')}
                        </Text>
                      </Checkbox>
                    </Stack>
                  </FormControl>
                </View>
                {/* </ScrollView> */}
              </KeyboardAvoidingView>
            ) : (
              <FormControl isRequired>
                <Stack style={profileStyles.formStack}>
                  <Text style={profileStyles.formLabel}>{t('name')}*</Text>
                  <TextInput
                    style={profileStyles.formInput}
                    placeholder="Имя"
                    value={updatedUserData.name}
                    onChangeText={text =>
                      setUpdatedUserData({ ...updatedUserData, name: text })
                    }
                  />
                </Stack>
                <Stack style={profileStyles.formStack}>
                  <Text style={profileStyles.formLabel}>
                    {t('phone_number')}*
                  </Text>
                  <TextInputMask
                    style={{
                      ...profileStyles.formInput,
                      backgroundColor: '#ccc',
                    }}
                    type={'custom'}
                    editable={false}
                    options={{ mask: '+380(99) 999-99-99' }}
                    placeholder="+380(99)123-45-67"
                    value={updatedUserData.phone}
                    onChangeText={text =>
                      setUpdatedUserData({ ...updatedUserData, phone: text })
                    }
                  />
                </Stack>
                <Stack style={profileStyles.formStack}>
                  <Text style={profileStyles.formLabel}>
                    {t('card_number')}*
                  </Text>
                  <TextInputMask
                    style={profileStyles.formInput}
                    type={'custom'}
                    options={{ mask: '9999 9999 9999 9999' }}
                    placeholder="1234 5678 9876 54321"
                    value={updatedUserData.card_number}
                    onChangeText={text =>
                      setUpdatedUserData({
                        ...updatedUserData,
                        card_number: text.replaceAll(' ', ''),
                      })
                    }
                  />
                </Stack>
                {/* {Platform.OS === 'ios' ? (
                <Stack style={{marginTop: 20}}>
                  <Text style={profileStyles.formLabel}>{t('currency')}*</Text>
                  <Picker
                    enabled={false}
                    style={{marginTop: 0}}
                    selectedValue={updatedUserData.currency}
                    onValueChange={item =>
                      setUpdatedUserData({...updatedUserData, currency: item})
                    }>
                    {currencyCodes.map((code, index) => (
                      <Picker.Item key={index} label={code} value={code} />
                    ))}
                  </Picker>
                </Stack>
              ) : (
                <Stack style={profileStyles.formStack}>
                  <Text style={profileStyles.formLabel}>{t('currency')}*</Text>
                  <Picker
                    enabled={false}
                    style={profileStyles.formSelect}
                    selectedValue={updatedUserData.currency}
                    onValueChange={item =>
                      setUpdatedUserData({...updatedUserData, currency: item})
                    }>
                    {currencyCodes.map((code, index) => (
                      <Picker.Item key={index} label={code} value={code} />
                    ))}
                  </Picker>
                </Stack>
              )} */}
                <Stack
                  style={[
                    profileStyles.formStack,
                    Platform.OS === 'ios' ? { marginTop: 20 } : {},
                  ]}>
                  <Checkbox
                    style={profileStyles.frmCheckbox}
                    value={updatedUserData.send_notifications ? true : false}
                    isChecked={
                      updatedUserData.send_notifications ? true : false
                    }
                    onChange={value =>
                      setUpdatedUserData({
                        ...updatedUserData,
                        send_notifications: value ? 1 : 0,
                      })
                    }>
                    <Text style={profileStyles.checkBoxTxt}>
                      {t('notify_trios')}
                    </Text>
                  </Checkbox>
                </Stack>
              </FormControl>
            )}
          </View>
          <HStack style={profileStyles.formBtnGroup}>
            <Box>
              <Pressable
                onPress={() => navigation.navigate('Profile')}
                style={{...profileStyles.formBtn, width: 130}}>
                <Text style={profileStyles.frmButtonText}>{t('cancel')}</Text>
              </Pressable>
            </Box>
            <Box>
              <Pressable onPress={handleUpdate} style={{...profileStyles.formBtn, width: 130, backgroundColor: theme.BLUE}}>
                <Text style={profileStyles.frmButtonText}>{t('submit')}</Text>
              </Pressable>
            </Box>
          </HStack>
          <HStack style={{ ...profileStyles.formBtnGroup, justifyContent: 'center' }}>
            <Box>
              <Pressable
                onPress={handleDelete}
                style={{
                  ...profileStyles.formBtn,
                  backgroundColor: 'red',
                  width: 'auto',
                }}>
                <Text style={profileStyles.frmButtonText}>{t('delete_profile')}</Text>
              </Pressable>
            </Box>
          </HStack>
        </View>
        {/* </ScrollView> */}
        <Footer />
        {isError && <ErrorModal error={isError} setIsError={setIsError} />}
        <ConfirmationModal
          modalVisible={confirmVisible}
          // caption={t('deleting_account_caption')}
          text={t('deleting_account_text')}
          setModalVisible={setConfirmVisible}
          setConfirmed={setConfirmed}
        />
        <AddCardModal
          modalVisible={showAddCard}
          setModalVisible={setShowAddCard}
          setIsLoading={setIsLoading}
          setIsError={setIsError}
          setCardUpdated={setCardUpdated}
          cardUpdated={cardUpdated}
        />
      </ScrollView>
    );
  }
};

export default ProfileEditing;
