import React, { useState, useContext, useEffect, useCallback } from 'react';
import { HStack } from 'native-base';
import {
  Text,
  View,
  ScrollView,
  Pressable,
  ActivityIndicator,
  Image,
  Platform,
  StyleSheet,
  KeyboardAvoidingView,
  Modal,
} from 'react-native';
import { Picker } from '@react-native-picker/picker';
import { DataTable } from 'react-native-paper';
import {
  widthPercentageToDP as wp,
  heightPercentageToDP as hp,
} from 'react-native-responsive-screen';
import { TextInputMask } from 'react-native-masked-text';
import { AuthContext } from '../../components/AuthProvider';
import AxiosInstance from '../../components/common/axiosInstance';
import Ionicons from 'react-native-vector-icons/Ionicons';
import Icon from 'react-native-vector-icons/FontAwesome';
import Clipboard from '@react-native-clipboard/clipboard';
import {
  ShareComponent,
  ShareWithChoice
} from '../../components/common/commonFunctions';
import Header from '../../components/common/Header';
import Footer from '../../components/common/Footer';
import Loader from '../../components/common/Loader';
import ErrorModal from '../../components/common/ErrorModal';
import InfoModal from '../../components/common/InfoModal';
import { capitalizeFirstLetter, formatCardNumber } from '../../components/common/commonFunctions';
import { OrderHeaderTable, OrderHeaderCard, OrderTable, OrderCard } from './OrderItem';

import ArrowLeft from '../../assets/img/arrow_left_white.svg';
import ToolTipModal from '../../components/common/ToolTipModal';

import Config from '../../config/config';

import { default as commonStylesF } from '../../styles/common';
import { default as commonStylesMobileF } from '../../styles/common_mobile';
import { default as balanceStylesF } from '../../styles/balance';
import { default as balanceStylesMobileF } from '../../styles/balance_mobile';
import { default as profileStylesDesktop } from '../../styles/profile';
import { default as profileStylesMobile } from '../../styles/profile_mobile';
import { default as listStylesF } from '../../styles/list';


const UserCashbackOrders = ({ navigation, route }) => {
  const {
    user,
    logout,
    t,
    screenWidth,
    theme,
    screenParams,
    addHistory,
    userCredentials
  } = useContext(AuthContext);
  const [refOrders, setRefOrders] = useState([]);
  const [sortField, setSortField] = useState('created_at');
  const [sortDirection, setSortDirection] = useState('ASC');
  const [isLoading, setIsLoading] = useState();
  const [info, setInfo] = useState(null);
  const [isError, setIsError] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const [promoModalVisible, setPromoModalVisible] = useState(false);
  const [isShowKeyboard, setIsShowKeyboard] = useState(false);
  const [withdraw, setWithdraw] = useState('');
  const [userOrder, setUserOrder] = useState(screenParams?.userOrder);
  const [userCards, setUserCards] = useState([]);
  const [fullRefLink, setFullRefLink] = useState('');
  const [cashbackDaysExpired, setCashbackDaysExpired] = useState(0);
  const [selectedCard, setSelectedCard] = useState(userCredentials?.card_number ?? '');
  const [emptyCard, setEmptyCard] = useState(false);
  // const [messages, setMessages] = useState([]);
  const [visible, setVisible] = useState(false);
  // console.log('userOrder', userOrder);
  const OrderHeader = screenWidth < 700 ? OrderHeaderCard : OrderHeaderTable;
  const Order = screenWidth < 700 ? OrderCard : OrderTable;

  const commonStyles =
    screenWidth < 1080
      ? commonStylesMobileF(wp, hp, theme)
      : commonStylesF(wp, hp, theme);
  const balanceStyles =
    screenWidth < 1080
      ? balanceStylesMobileF(wp, hp, theme)
      : balanceStylesF(wp, hp, theme);
  let fontSizeBig = Math.ceil(screenWidth / 18) < 40 ? Math.ceil(screenWidth / 14) : 40;
  let fontSizeMd = Math.ceil(screenWidth / 20) < 24 ? Math.ceil(screenWidth / 16) : 24;
  let profileStyles =
    screenWidth < 1080
      ? profileStylesMobile(wp, hp, fontSizeBig, fontSizeMd, theme)
      : profileStylesDesktop(wp, hp, fontSizeBig, fontSizeMd, theme);
  const listStyles = listStylesF(theme);

  const doWithdraw = async () => {
    if (!selectedCard) {
      setEmptyCard(true);
      return;
    }
    setIsLoading(true);
    setModalVisible(false);
    const api = AxiosInstance({ user, logout });
    await api('/api/v1/withdraw', {
      method: 'POST',
      mode: 'cors',
      data: {
        amount: parseFloat(withdraw),
        program: 'cashback',
        order_id: userOrder?.id,
        card_id: selectedCard,
      },
    })
      .then(response => {
        if (response.status === 201) {
          setWithdraw(0);
          setUserOrder((prevState) => {
            return { ...prevState, cashback_status: 'withdraw_requested' }
          });
          setInfo(t('withdraw_request_sended'));
        } else if (response.status === 'error') {
          setIsError(t('somthing_wrong'));
          console.log(response);
        }
      })
      .catch(error => {
        setIsError(error);
      })
      .finally(() => {
        setIsLoading(false);
        // setModalVisible(false);
      });
  };

  const getUserCards = async () => {
    const api = AxiosInstance({ user, logout });
    await api(`${Config.API_URL}/api/v1/user_cards`, {
      method: 'GET',
      mode: 'cors',
    }).then(response => {
      if (response.status === 200) {
        console.log(response.data.message);
        setUserCards(response.data.message);
      }
    }).catch(error => {
      setIsError(t('server_error'));
      console.log(error);
    });
  }

  useEffect(() => {
    const getRefOrders = async () => {
      if (!userOrder?.id) {
        setIsError(t('empty_order_id'));
        return false;
      }
      setIsLoading(true);
      const api = AxiosInstance({ user, logout });
      await api(`${Config.API_URL}/api/v1/cashback/${userOrder.id}/orders?order_field=${sortField}&order=${sortDirection}`, {
        method: 'GET',
        mode: 'cors',
      }).then(response => {
        if (response.status === 200) {
          setRefOrders(response.data.message);
        }
      }).catch(error => {
        setIsError(t('server_error'));
        console.log(error);
      }).finally(() => {
        setIsLoading(false);
      });
    }

    getRefOrders();

  }, [user, logout, sortField, sortDirection, userOrder?.id, t]);

  useEffect(() => {
    setFullRefLink(
      `https://${userOrder?.order_ref_link}.${Config.SHOP_MAIN_DOMAIN}`
    );
    if (userOrder && userOrder?.expired_at) {
      const now = new Date();
      const expiredAt = new Date(userOrder?.expired_at);
      setCashbackDaysExpired(Math.floor((expiredAt - now) / (1000 * 60 * 60 * 24)));
    }
  }, [userOrder]);

  useEffect(() => {
    if (Platform.OS !== 'web') {
      addHistory(route.name);
    }
  }, [addHistory, route]);

  // if (isLoading) {
  //   return <Loader />
  // }
  if (!userOrder) {
    return (
      <ScrollView contentContainerStyle={commonStyles.container}>
        <Header navigation={navigation} route={route} />
        <View style={commonStyles.contentContainer}>
          <View style={commonStyles.headerContainer}>
            <Pressable
              style={commonStyles.headBackButton}
              onPress={() => navigation.navigate('MyOrders')}>
              {Platform.OS === 'web' ? (
                <Image source={ArrowLeft} style={commonStyles.arrowLeft} />
              ) : (
                <ArrowLeft style={commonStyles.arrowLeft} />
              )}
            </Pressable>
          </View>
        </View>
        <Footer navigation={navigation} />
      </ScrollView>
    );
  }
  return (
    <ScrollView contentContainerStyle={commonStyles.container}>
      <Header navigation={navigation} route={route} />
      <View style={commonStyles.contentContainer}>
        <View style={commonStyles.headerContainer}>
          <Pressable
            style={commonStyles.headBackButton}
            onPress={() => navigation.navigate('MyOrders')}>
            {Platform.OS === 'web' ? (
              <Image source={ArrowLeft} style={commonStyles.arrowLeft} />
            ) : (
              <ArrowLeft style={commonStyles.arrowLeft} />
            )}
          </Pressable>
          <View style={commonStyles.contentHead}>
            <Text style={commonStyles.contentHeadText}>{t('cashback_orders')}</Text>
          </View>
        </View>
        <View style={{ ...styles.refInfo, minHeight: 550 }}>
          <Text style={styles.refName}>
            {t('your_order')}
          </Text>
          {cashbackDaysExpired > 0 && (
            <Text style={{ ...styles.refName, fontSize: 16 }}>{t('your_cashback_active')} {cashbackDaysExpired} {t('days')}</Text>
          )}
          <View style={profileStyles.personalLink}>
            <View>
              <View style={{
                ...profileStyles.refLinkCaption,
                flexDirection: 'row',
                padding: 10,
                justifyContent: 'center',
              }
              }>
                <Text style={profileStyles.refLinkCaption}>
                  {t('your_personal_link')}:
                </Text>
                <Pressable onPress={() => setVisible(!visible)} style={profileStyles.icon}>
                  <Text style={profileStyles.iconText}>?</Text>
                </Pressable>
              </View>
              <HStack style={profileStyles.infoContainer}>
                <View style={profileStyles.refLinkContainer}>
                  <Text style={profileStyles.refLinkText}>{fullRefLink}</Text>
                </View>
                <Pressable
                  onPress={() => {
                    Clipboard.setString(fullRefLink);
                    setInfo(t('ref_copied'));
                  }}>
                  {Platform.OS === 'ios' ? (
                    <Ionicons name="copy" size={25} color="#007AFF" />
                  ) : (
                    <Icon
                      name="copy"
                      size={30}
                      color={theme.FONT_COLOR_BLACK}
                    />
                  )}
                </Pressable>
                {Platform.OS !== 'web' ? (
                  <ShareWithChoice url={fullRefLink} />
                ) : (
                  <ShareComponent
                    url={fullRefLink}
                  />
                )}
              </HStack>
            </View>
          </View>
          <DataTable style={listStyles.dataTable}>
            <OrderHeader indicator={true} expired_at={true} />
            {userOrder && (
              <Order item={userOrder} indicator={true} />
            )}
          </DataTable>
          <HStack style={balanceStyles.balanceFooterContainer}>

            {(userOrder?.cashback_status === 'completed') 
              ? (
                <HStack style={{ ...balanceStyles.btnGroup, justifyContent: "center" }}>
                  <Pressable
                    style={balanceStyles.balanceBtnWithdraw}
                    onPress={() => {
                      setWithdraw(userOrder?.cashback_withdraw || 0);
                      getUserCards();
                      setModalVisible(true);
                    }}>
                    <Text style={balanceStyles.btnTextWhite}>
                      {t('do_withdraw_request')}
                    </Text>
                  </Pressable>
                  {/* <Pressable
                    style={balanceStyles.balanceBtnWithdraw}
                    onPress={() => {
                      setPromoModalVisible(true);
                    }}>
                    <Text style={balanceStyles.btnTextWhite}>
                      {t('promocode')}
                    </Text>
                  </Pressable> */}
                </HStack>
              )
              : (
                <View style={{ ...balanceStyles.btnGroup, justifyContent: "center" }}>
                  <Text style={{
                    textAlign: 'center',
                    fontFamily: theme.FONT_FAMILY_MEDIUM,
                    fontSize: 20
                  }}
                >
                  {t('expired_cashback_description')}</Text>
                </View>
              )}

          </HStack>
          <Text style={{ ...styles.refName, lineHeight: 80 }}>
            {t('referal_orders')}
          </Text>
        </View>

        <DataTable style={listStyles.dataTable}>
          <OrderHeader
            sortField={sortField}
            sortDirection={sortDirection}
            setSortField={setSortField}
            setSortDirection={setSortDirection}
            name={true}
            expired_at={true}
          />
          {refOrders && refOrders.map(
            item => (<Order item={item} key={item.id} />)
          )}
        </DataTable>
        <HStack style={{ ...balanceStyles.balanceFooterContainer, padding: 10 }}>
        </HStack>
      </View>
      <Footer navigation={navigation} />
      {modalVisible && (
        <KeyboardAvoidingView
          behavior={Platform.OS === 'ios' ? 'padding' : ''}>
          <Modal
            animationType="none"
            transparent={true}
            visible={true}
            onRequestClose={() => {
              setModalVisible(false);
            }}
            style={{ alignItems: 'center', justifyContent: 'center' }}>
            <View
              style={{
                ...balanceStyles.modalContainer,
                marginBottom: isShowKeyboard ? 20 : 150,
              }}>
              <View style={{ ...balanceStyles.modalContent, height: 'auto' }}>
                <View style={balanceStyles.modalHead}>
                  <Pressable onPress={() => setModalVisible(false)}>
                    <Image
                      style={balanceStyles.iconClose}
                      source={require('../../assets/img/icon_close.png')}
                    />
                  </Pressable>
                </View>

                <View style={{ ...balanceStyles.modalBody, height: 'auto' }}>
                  <Text style={
                    {
                      ...balanceStyles.modalCaption,
                      fontSize: 20,
                      height: 30,
                      lineHeight: 30
                    }
                  }>
                    {capitalizeFirstLetter(t('form_withdraw_request'))}
                  </Text>
                  <View style={{ ...balanceStyles.withdrawContent, gap: 10 }}>
                    <Text style={balanceStyles.modalText}>{t("you_can_order_payment")} ({withdraw} {t("UAH")})</Text>
                    {emptyCard && (<Text style={{ ...balanceStyles.modalText, color: 'red' }}>{t('select_card')}</Text>)}
                    {userCards.length > 0 ? (
                      <Picker
                        // style={emptyCard ? { borderColor: 'red' } : ''}
                        selectedValue={selectedCard}

                        onValueChange={(itemValue) => {
                          setSelectedCard(itemValue);
                          if (itemValue) {
                            setEmptyCard(false);
                          }
                        }}
                      >
                        <Picker.Item key={0} label={t('select_card')} value="" />
                        {userCards.map((card) => (
                          <Picker.Item key={card.id} label={formatCardNumber(card.card_number)} value={card.id} />
                        ))}
                      </Picker>
                    ) : (
                      <>
                        <Text style={{ ...balanceStyles.modalText, fontWeight: '700' }}>{t('you_do_not_have_card')}</Text>
                        <Pressable
                          onPress={() => {
                            navigation.navigate('ProfileEditing');
                          }}
                        >
                          <Text>{t('add_card')}</Text>
                        </Pressable>
                      </>
                    )}
                    <Text style={balanceStyles.modalText}>{t("or_use_promo_for_new_orders")} ({userOrder?.cashback_calculated} {t("UAH")})</Text>
                    <HStack style={{ justifyContent: "center" }}>
                      <Text style={{
                        padding: 5,
                        fontWeight: 700,
                      }}>{userOrder?.promocode.substr(0, 8)}</Text>
                      <Pressable
                        onPress={() => {
                          Clipboard.setString(userOrder?.promocode.substr(0, 8));
                          setInfo(t('ref_copied'));
                        }}>
                        {Platform.OS === 'ios' ? (
                          <Ionicons name="copy" size={25} color="#007AFF" />
                        ) : (
                          <Icon
                            name="copy"
                            size={30}
                            color={theme.FONT_COLOR_BLACK}
                          />
                        )}
                      </Pressable>
                    </HStack>
                  </View>
                  <HStack style={{ ...balanceStyles.modalBtnGroup, paddingBottom: 20 }}>
                    <Pressable
                      style={balanceStyles.modalBtn}
                      onPress={() => {
                        setModalVisible(false);
                        setIsShowKeyboard(false);
                      }}>
                      <Text style={balanceStyles.btnTextWhite}>
                        {t('cancel')}
                      </Text>
                    </Pressable>
                    <Pressable
                      style={{ ...balanceStyles.modalBtn, width: 180 }}
                      // disabled={!selectedCard}
                      onPress={() => {
                        doWithdraw();
                        setIsShowKeyboard(false);
                      }}>
                      <Text style={balanceStyles.btnTextWhite}>
                        {t('do_withdraw_request')}
                      </Text>
                    </Pressable>
                  </HStack>
                  {/* <Text style={styles.error}>Errors: {errors ? JSON.stringify(errors) : ''}</Text> */}
                </View>
              </View>
            </View>
          </Modal>
        </KeyboardAvoidingView>
      )}
      {promoModalVisible && (
        <Modal
          animationType="none"
          transparent={true}
          visible={true}
          onRequestClose={() => {
            setPromoModalVisible(false);
          }}
          style={{ alignItems: 'center', justifyContent: 'center' }}>
          <View
            style={balanceStyles.modalContainer}>
            <View style={balanceStyles.modalContent}>
              <View style={balanceStyles.modalHead}>
                <Pressable onPress={() => setPromoModalVisible(false)}>
                  <Image
                    style={balanceStyles.iconClose}
                    source={require('../../assets/img/icon_close.png')}
                  />
                </Pressable>
              </View>

              <View style={{ ...balanceStyles.modalBody, width: "90%" }}>
                <Text style={{ ...balanceStyles.modalCaption, width: "100%" }}>
                  {t('your_promocode')}
                </Text>
                <View style={{ ...balanceStyles.inputContainer, width: "100%", height: "50%" }}>
                  <Text>{t('promocode_info')}</Text>
                  <HStack style={{ justifyContent: "center" }}>
                    <Text style={{
                      padding: 5,
                      fontWeight: 700,
                    }}>{userOrder?.promocode.substr(0, 8)}</Text>
                    <Pressable
                      onPress={() => {
                        Clipboard.setString(userOrder?.promocode.substr(0, 8));
                        setInfo(t('ref_copied'));
                      }}>
                      {Platform.OS === 'ios' ? (
                        <Ionicons name="copy" size={25} color="#007AFF" />
                      ) : (
                        <Icon
                          name="copy"
                          size={30}
                          color={theme.FONT_COLOR_BLACK}
                        />
                      )}
                    </Pressable>
                  </HStack>
                </View>
                <HStack style={balanceStyles.modalBtnGroup}>
                  <Pressable
                    style={balanceStyles.modalBtn}
                    onPress={() => {
                      setPromoModalVisible(false);
                    }}>
                    <Text style={balanceStyles.btnTextWhite}>
                      {t('close')}
                    </Text>
                  </Pressable>
                </HStack>
              </View>
            </View>
          </View>
        </Modal>
      )}
      {isError && <ErrorModal error={isError} setIsError={setIsError} />}
      {info && <InfoModal info={info} setInfo={setInfo} />}
      {isLoading === true && (
        <ActivityIndicator
          style={styles.activityIndicator}
          color={theme.BLUE}
          size="large"
        />
      )}
      <ToolTipModal visible={visible} setVisible={setVisible} />

    </ScrollView>
  );
}

const styles = StyleSheet.create({
  activityIndicator: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'transparent', // Прозрачный фон
  },
  refInfo: {
    flex: 1,
    marginTop: 47,
    width: '90%',
    height: 40,
    gap: 11,
  },
  refName: {
    fontFamily: 'Inter',
    fontSize: 40,
    fontWeight: 'normal',
    lineHeight: 48,
    textAlign: 'center',
    width: '100%',
  }
});

export default UserCashbackOrders;