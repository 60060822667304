import React from "react";
import {StyleSheet} from 'react-native';

const styles = (theme) => StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  centeredView: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
  },
  modalView: {
    width: '60%',
    height: '50%',
    backgroundColor: theme.WHITE,
    borderRadius: 10,
    padding: 20,
    alignItems: 'center',
    shadowColor: '#000',
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 0.25,
    shadowRadius: 4,
    elevation: 5,
  },
  modalTitle: {
    fontFamily: theme.FONT_FAMILY,
    fontSize: 18,
    fontWeight: 'bold',
    marginBottom: 10,
  },
  modalContent: {
    // maxHeight: 200,
    marginBottom: 20,
  },
  closeButton: {
    backgroundColor: theme.BLUE,
    borderRadius: 5,
    padding: 10,
  },
  closeButtonText: {
    color: theme.FONT_COLOR_WHITE,
    fontWeight: 'bold',
  },
});

export default styles;